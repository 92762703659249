import { useState } from 'react';
import GlobalStyle from '../../style/Global';
import FadeIn from 'react-fade-in';
import AddIcon from '@material-ui/icons/AddCircle';
import {
    Grid,
    Typography,
    Button,
    IconButton,
    withStyles
} from '@material-ui/core';


function Settings({
    stylist,
    DB,
    AUTH
}) {

    return (
        <div>
            <FadeIn>
                <Grid container item xs={12} style={{padding: '1rem'}}>
                    <Grid item xs={12} style={{position: 'relative', color: '#222', padding: '1rem'}}>
                        <Typography style={{
                            fontFamily: 'helvetica neue',
                            fontSize: '1.5rem',
                        }}>
                            Settings
                        </Typography>
                        <div style={{flexGrow: 1}}/>
                        {/* <IconButton aria-label="add" style={{position: 'absolute', top: '30%', right: '1rem'}} size="large">
                            <AddIcon style={{color: '#222'}}/>
                        </IconButton> */}
                    </Grid>

                    <Grid item xs={6} style={{padding: '1rem'}}>
                        <Button fullWidth variant="contained" style={{backgroundColor: '#444', color: '#f5f5f5', textTransform: 'none', marginLeft: 0, marginBottom: '1rem'}}>
                            Update Payout Account
                        </Button>
                    </Grid>
                    <Grid item xs={6} style={{padding: '1rem'}}>
                        <Button fullWidth variant="contained" style={{backgroundColor: '#444', color: '#f5f5f5', textTransform: 'none', marginLeft: 0, marginBottom: '1rem'}}>
                            Update Payment Method
                        </Button>
                    </Grid>              
                    <Grid item xs={6} style={{padding: '1rem'}}>
                        <Button fullWidth variant="contained" style={{backgroundColor: '#444', color: '#f5f5f5', textTransform: 'none', marginLeft: 0, marginBottom: '1rem'}}>
                            Update Email Address
                        </Button>
                    </Grid>
                    <Grid item xs={6} style={{padding: '1rem'}}>
                        <Button fullWidth variant="contained" style={{backgroundColor: '#444', color: '#f5f5f5', textTransform: 'none', marginLeft: 0, marginBottom: '1rem'}}>
                            Update Password
                        </Button>
                    </Grid>
                </Grid>
            </FadeIn>
        </div>
    )
}

export default withStyles(GlobalStyle)(Settings);