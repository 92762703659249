import { useState } from 'react';
import {
  Grid,
  Typography,
  SnackbarContent,
  withStyles
} from '@material-ui/core';

import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import GlobalStyle from '../../style/Global';
import logo from '../../assets/images/logo_light.png';

import FadeIn from 'react-fade-in';

import LogIn from './LogIn';
import ForgotPassword from './ForgotPassword';


function Index({classes, AUTH, DB, setRoute, setStylist}) {
  
  const [scene, setScene] = useState('log in');
  const [message, setMessage] = useState(null);

  return (
    <Grid container style={{position: 'relative', minHeight: '100vh', maxHeight: '120vh', overflow: 'hidden', backgroundColor: '#222'}}>
      {message && <FadeIn><SnackbarContent
        className={message.style}
        message={message.content}
      /></FadeIn>}
      <Grid item xs={12} md={4} className={classes.authContainer}>
        <Grid item xs={12}>
          <a href='/'><img alt="logo" src={logo} style={{height: '5rem', marginBottom: '1rem'}}/></a>
        </Grid>
        <Grid>
          <Typography
            style={{color: 'goldenrod', fontSize: '1rem', fontFamily: 'Helvetica Neue', fontWeight: '300', lineHeight: 1, marginBottom: '0.25rem'}}
          >
            Grow Your Business With Us
          </Typography>
        </Grid>
        {scene === 'log in' && <FadeIn transitionDuration="600"><LogIn AUTH={AUTH} DB={DB} setStylist={setStylist} setRoute={setRoute} setScene={setScene} setMessage={setMessage}/></FadeIn>}
        {scene === 'forgot password' && <FadeIn transitionDuration="600"><ForgotPassword AUTH={AUTH} setRoute={setRoute} setScene={setScene} setMessage={setMessage}/></FadeIn>}

        <div style={{marginTop: '10rem'}}>
          <InstagramIcon onClick={() => window.open('http://instagram.com/thecutlife', '_blank')} style={{cursor: 'pointer', color: '#f5f5f5', marginRight: '1rem'}}/> <TwitterIcon onClick={() => window.open('http://twitter.com/thecutlife', '_blank')} style={{cursor: 'pointer', color: '#f5f5f5'}}/>
        </div>
        <Typography variant="caption" style={{display: 'block', marginTop: '1rem', fontFamily: 'helvetica', fontWeight: '200', color: '#f5f5f5', lineHeight: 1}}>
            © 2020 The Cut Life, Inc.
        </Typography>
      </Grid>
      <Grid item xs={false} md={8} className={classes.fullBackgroundImage}></Grid>
    </Grid>
  )

}

export default withStyles(GlobalStyle)(Index);
