import { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Button,
    IconButton,
    InputAdornment,
    CircularProgress,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    withStyles
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddCircle';
import ActiveIcon from '@material-ui/icons/CheckCircle';
import InactiveIcon from '@material-ui/icons/Cancel';

import GlobalStyle from '../../style/Global';
import FadeIn from 'react-fade-in';


function Content ({
    classes,
    stylist,
    setMessage,
    DB,
    CDN,
}) {

    const initialContentData = {
        caption: null,
        image: null,
        stylistId: stylist.id,
        type: 'image',
        tags: '',
        placeholderImage: 'https://firebasestorage.googleapis.com/v0/b/the-cut-life.appspot.com/o/placeholder.jpg?alt=media&token=b40c0e15-4984-4038-a7c4-578602bf7d7e',
        active: true
    };

    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(initialContentData);
    const [allContent, setAllContent] = useState(null);
    const [editing, setEditing] = useState(false);
    const [contentFormVisible, setContentFormVisible] = useState(false);
    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }
    
    const getAllContent = () => {
        DB.collection('content').where('stylistId', '==', stylist.id).get()
        .then((allContentSnapshot) => {
            const allContentContainer = [];

            allContentSnapshot.forEach((contentDocument) => {
                const content = contentDocument.data();
                content.id = contentDocument.id;
                content.tags = content.tags.join(',');

                allContentContainer.push(content);
            });

            setAllContent(allContentContainer);
            setLoading(false);
        })
    }

    const addContent = () => {
        if (!content.caption || !image || (content.type === 'video' && !video)) {
            return showFeedback('Missing content details...', classes.error);
        }

        setLoading(true);

        DB.collection('content').add({...content, tags: content.tags.split(',')})
        .then((contentRef) => {
            const imageRef = CDN.ref().child(`content/images/${contentRef.id}`);

            imageRef.put(image)
            .then(() => {
                imageRef.getDownloadURL()
                .then((iUrl) => {
                    contentRef.update({
                        image: iUrl
                    })
                    .then(() => {
                        if (content.type === 'video') {
                            const videoRef = CDN.ref().child(`content/video/${contentRef.id}`);

                            videoRef.put(video)
                            .then(() => {
                                videoRef.getDownloadURL()
                                .then((vUrl) => {
                                    contentRef.update({
                                        video: vUrl
                                    })
                                    .then(() => {
                                        showFeedback('Content added successfully!', classes.success);
                                        getAllContent();
                                        setContentFormVisible(false);
                                        setContent(initialContentData);
                                        setImage(null);
                                        setVideo(null);
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        showFeedback(error, classes.error);
                                    })
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    showFeedback(error, classes.error);
                                })
                            })
                            .catch((error) => {
                                setLoading(false);
                                showFeedback(error, classes.error);
                            })
                        } else {
                            showFeedback('Content added successfully!', classes.success);
                            getAllContent();
                            setContentFormVisible(false);
                            setContent(initialContentData);
                            setImage(null);
                            setVideo(null);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        showFeedback(error, classes.error);
                    })
                })
                .catch((error) => {
                    setLoading(false);
                    showFeedback(error, classes.error);
                })
            })
            .catch((error) => {
                setLoading(false);
                showFeedback(error, classes.error);
            })
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error, classes.error);
        })
    };

    const updateContent = (toggleContentAvailability = false) => {
        if (!content.caption) {
            return showFeedback('Missing content details...', classes.error);
        }

        setLoading(true);

        if (toggleContentAvailability) {
            return DB.collection('content').doc(content.id).update({
                active: !content.active
            })
            .then(() => {
                getAllContent();
                showFeedback(`Content ${content.active ? 'deactivated' : 'activated'} successfully!`, classes.success);
                setContentFormVisible(false);
                setEditing(false);
                setContent(initialContentData);
                setImage(null);
                setVideo(null);
            })
        }

        const contentRef = DB.collection('content').doc(content.id);
        
        contentRef.update({...content, tags: content.tags.split(',')})
        .then(() => {
            if (image) {
                const imageRef = CDN.ref().child(`content/images/${content.id}`);

                imageRef.put(image)
                .then(() => {
                    imageRef.getDownloadURL()
                    .then((url) => {
                        contentRef.update({
                            image: url
                        })
                        .then(() => {
                            showFeedback('Content updated successfully!', classes.success);
                            getAllContent();
                            setContentFormVisible(false);
                            setContent(initialContentData);
                            setImage(null);
                            setVideo(null);
                        })
                        .catch((error) => {
                            setLoading(false);
                            showFeedback(error, classes.error);
                        })
                    })
                    .catch((error) => {
                        setLoading(false);
                        showFeedback(error, classes.error);
                    })
                })
                .catch((error) => {
                    setLoading(false);
                    showFeedback(error, classes.error);
                })
            } else {
                showFeedback('Content updated successfully!', classes.success);
                getAllContent();
                setContentFormVisible(false);
                setContent(initialContentData);
                setImage(null);
                setVideo(null);
            }
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error, classes.error);
        })
    };

    const clearContent = () => {
        setContentFormVisible(false);
        setEditing(false);
        setContent(initialContentData);
        setImage(null);
    }

    useEffect(() => {
        getAllContent();
    }, [])

    return (
        <div>
            <FadeIn>
                <Grid container item xs={12} style={{padding: '1rem'}}>
                    <Grid item xs={12} style={{position: 'relative', color: '#222', padding: '1rem'}}>
                        <Typography style={{
                            fontFamily: 'helvetica neue',
                            fontSize: '1.5rem',
                        }}>
                            Content
                        </Typography>
                        <div style={{flexGrow: 1}}/>
                        <IconButton aria-label="add" onClick={() => setContentFormVisible(true)} style={{position: 'absolute', top: '30%', right: '1rem'}}>
                            <AddIcon style={{color: '#222'}}/>
                        </IconButton>
                    </Grid>
                    {allContent && allContent.length < 1 && <Typography style={{marginTop: '1rem', padding: '1rem', fontFamily: 'helvetica neue', fontSize: '1rem', fontWeight: '400'}}>You haven't added any content, yet.</Typography>}
                    {allContent && allContent.length > 0 && allContent.map((currentContent) => { return (<Grid item key={currentContent.id} xs={12} md={4} style={{padding: '1rem'}}>
                        <Card onClick={() => {setContent(currentContent); setEditing(true); setContentFormVisible(true)}}>
                            <CardActionArea>
                                <CardMedia
                                    style={{height: '300px', position: 'top'}}
                                    image={currentContent.image ? currentContent.image : currentContent.placeholderImage}
                                />
                                <CardContent>
                                    {/* <Typography gutterBottom variant="h5" component="h2" style={{color: 'goldenrod'}}>
                                        {currentContent.caption}
                                    </Typography> */}
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        <span style={{display: 'block'}}>
                                            Type: <span style={{
                                                fontFamily: 'helvetica neue',
                                                fontWeight: '400',
                                            }}>{currentContent.type}</span>
                                        </span>
                                        <span style={{display: 'block'}}>
                                            Tags: <span style={{
                                                fontFamily: 'helvetica neue',
                                                fontWeight: '400',
                                            }}>{currentContent.tags}</span>
                                        </span>
                                        <span style={{display: 'block', marginBottom: '1rem'}}>
                                            Active: <span style={{
                                                fontFamily: 'helvetica neue',
                                                fontWeight: '400',
                                            }}>{currentContent.active ? <ActiveIcon style={{verticalAlign: 'middle', fontSize: '1rem', color: 'forestgreen'}}/> : <InactiveIcon style={{verticalAlign: 'middle', fontSize: '1rem', color: 'red'}}/> }</span>
                                        </span>
                                        <span style={{
                                            display: 'block',
                                            fontFamily: 'helvetica neue',
                                            fontWeight: '400',
                                            overflow: 'hidden',
                                            width: '100%',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {currentContent.caption}
                                        </span>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>)})}
                </Grid>
            </FadeIn>

            <Dialog open={contentFormVisible} onClose={() => loading ? null : clearContent()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: 'goldenrod'}}/> : editing ? 'Edit Content' : 'Add Content'} {loading && content.type === 'video' && ' uploading...'}</DialogTitle>
                {editing && <DialogContentText style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
                    Active: {content && content.active ? <ActiveIcon style={{verticalAlign: 'middle', fontSize: '1rem', color: 'forestgreen'}}/> : <InactiveIcon style={{verticalAlign: 'middle', fontSize: '1rem', color: 'red'}}/>}
                </DialogContentText>}
                <DialogContent>
                    <TextField
                        fullWidth
                        multiline
                        rows={7}
                        disabled={loading}
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        label="Caption"
                        value={content && content.caption}
                        onChange={(event) => setContent({...content, caption: event.target.value})}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        disabled={loading}
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        label="Tags"
                        value={content && content.tags}
                        helperText="Separate with commas (color,pixie,lines)"
                        onChange={(event) => setContent({...content, tags: event.target.value})}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        select
                        label="Type"
                        variant="outlined"
                        disabled={loading || editing}
                        value={content && content.type}
                        onChange={(event) => setContent({...content, type: event.target.value})}
                        className={`${classes.inputDark} ${classes.blockInput}`}
                    >
                        {['image', 'video'].map((type) => { 
                            return (<MenuItem key={type} value={type}>{type}</MenuItem>)
                        })}
                    </TextField>
                    <TextField
                        disabled={loading}
                        type="file"
                        accept="image/*"
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        helperText="Content Image (min. 400x800), vertical images work best"
                        onChange={(event) => setImage(event.target.files[0])}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    {content.type === 'video' && !editing && <TextField
                        disabled={loading}
                        type="file"
                        accept="video/*"
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        helperText="Content Video (vertical orientation works best)"
                        onChange={(event) => setVideo(event.target.files[0])}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />}

                </DialogContent>
                <DialogActions style={{display: 'block', padding: '1rem 1.5rem 0'}}>
                    <Button fullWidth disabled={loading} onClick={() => editing ? updateContent() : addContent()} variant="contained" style={{backgroundColor: '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                        {editing ? 'Update' : 'Add'} Content
                    </Button>
                    <Button fullWidth disabled={loading} onClick={clearContent} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginLeft: 0, marginBottom: '1rem'}}>
                        Cancel
                    </Button>
                    {editing && <Button fullWidth disabled={loading} onClick={() => updateContent(true)} variant="contained" style={{backgroundColor: content.active ? 'red' : 'forestgreen', color: '#f5f5f5', textTransform: 'none', marginLeft: 0, marginBottom: '1rem'}}>
                        {content && content.active ? 'Deactivate' : 'Activate'}
                    </Button>}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default withStyles(GlobalStyle)(Content);