import { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Button,
    IconButton,
    InputAdornment,
    CircularProgress,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    withStyles
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddCircle';
import ActiveIcon from '@material-ui/icons/CheckCircle';
import InactiveIcon from '@material-ui/icons/Cancel';

import GlobalStyle from '../../style/Global';
import FadeIn from 'react-fade-in';
import { initial } from 'underscore';


function Products ({
    classes,
    stylist,
    setMessage,
    DB,
    CDN,
}) {

    const initialProductData = {
        name: null,
        description: null,
        duration: null,
        image: null,
        stylistId: stylist.id,
        price: 0,
        placeholderImage: 'https://firebasestorage.googleapis.com/v0/b/the-cut-life.appspot.com/o/placeholder.jpg?alt=media&token=b40c0e15-4984-4038-a7c4-578602bf7d7e',
        active: true
    };

    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState(initialProductData);
    const [products, setProducts] = useState(null);
    const [editing, setEditing] = useState(false);
    const [productFormVisible, setProductFormVisible] = useState(false);
    const [image, setImage] = useState(null);

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }
    
    const getProducts = () => {
        DB.collection('products').where('stylistId', '==', stylist.id).get()
        .then((productsSnapshot) => {
            const productsContainer = [];

            productsSnapshot.forEach((productDocument) => {
                const product = productDocument.data();
                product.id = productDocument.id;

                productsContainer.push(product);
            });

            setProducts(productsContainer);
            setLoading(false);
        })
    }

    const addProduct = () => {
        if (!product.name || !product.description || !product.url || !product.price) {
            return showFeedback('Missing product details...', classes.error);
        }

        setLoading(true);

        DB.collection('products').add({...product, price: product.price})
        .then((productRef) => {
            if (!image) {
                showFeedback('Product added successfully!', classes.success);
                getProducts();
                setProductFormVisible(false);
                setProduct(initialProductData);
                setImage(null);
                return;
            }

            const imageRef = CDN.ref().child(`products/images/${productRef.id}`);

            imageRef.put(image)
            .then(() => {
                imageRef.getDownloadURL()
                .then((url) => {
                    productRef.update({
                        image: url
                    })
                    .then(() => {
                        showFeedback('Product added successfully!', classes.success);
                        getProducts();
                        setProductFormVisible(false);
                        setProduct(initialProductData);
                        setImage(null);
                    })
                    .catch((error) => {
                        setLoading(false);
                        showFeedback(error, classes.error);
                    })
                })
                .catch((error) => {
                    setLoading(false);
                    showFeedback(error, classes.error);
                })
            })
            .catch((error) => {
                setLoading(false);
                showFeedback(error, classes.error);
            })
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error, classes.error);
        })
    };

    const updateProduct = (toggleProductAvailability = false) => {
        if (!product.name || !product.description || !product.url || !product.price) {
            return showFeedback('Missing product details...', classes.error);
        }

        setLoading(true);

        if (toggleProductAvailability) {
            return DB.collection('products').doc(product.id).update({
                active: !product.active
            })
            .then(() => {
                getProducts();
                showFeedback(`Product ${product.active ? 'deactivated' : 'activated'} successfully!`, classes.success);
                setProductFormVisible(false);
                setEditing(false);
                setProduct(initialProductData);
                setImage(null);
            })
        }

        const productRef = DB.collection('products').doc(product.id);
        
        productRef.update({...product})
        .then(() => {
            if (!image) {
                showFeedback('Product updated successfully!', classes.success);
                getProducts();
                setProductFormVisible(false);
                setProduct(initialProductData);
                setEditing(false);
                setImage(null);
                return;
            }

            const imageRef = CDN.ref().child(`product/images/${product.id}`);

            imageRef.put(image)
            .then(() => {
                imageRef.getDownloadURL()
                .then((url) => {
                    productRef.update({
                        image: url
                    })
                    .then(() => {
                        showFeedback('Product updated successfully!', classes.success);
                        getProducts();
                        setProductFormVisible(false);
                        setProduct(initialProductData);
                        setImage(null);
                    })
                    .catch((error) => {
                        setLoading(false);
                        showFeedback(error, classes.error);
                    })
                })
                .catch((error) => {
                    setLoading(false);
                    showFeedback(error, classes.error);
                })
            })
            .catch((error) => {
                setLoading(false);
                showFeedback(error, classes.error);
            })
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error, classes.error);
        })
    };

    const clearProduct = () => {
        setProductFormVisible(false);
        setEditing(false);
        setProduct(initialProductData);
        setImage(null);
    }

    useEffect(() => {
        getProducts();
    }, [])

    return (
        <div>
            <FadeIn>
                <Grid container item xs={12} style={{padding: '1rem'}}>
                    <Grid item xs={12} style={{position: 'relative', color: '#222', padding: '1rem'}}>
                        <Typography style={{
                            fontFamily: 'helvetica neue',
                            fontSize: '1.5rem',
                        }}>
                            Products
                        </Typography>
                        <div style={{flexGrow: 1}}/>
                        <IconButton aria-label="add" onClick={() => setProductFormVisible(true)} style={{position: 'absolute', top: '30%', right: '1rem'}}>
                            <AddIcon style={{color: '#222'}}/>
                        </IconButton>
                    </Grid>
                    {products && products.length < 1 && <Typography style={{marginTop: '1rem', padding: '1rem', fontFamily: 'helvetica neue', fontSize: '1rem', fontWeight: '400'}}>You haven't added any products, yet.</Typography>}
                    {products && products.length > 0 && products.map((product) => { return (<Grid item key={product.id} xs={12} md={4} style={{padding: '1rem'}}>
                        <Card onClick={() => {setProduct(product); setEditing(true); setProductFormVisible(true)}}>
                            <CardActionArea>
                                <CardMedia
                                    style={{height: '250px', position: 'top'}}
                                    image={product.image ? product.image : product.placeholderImage}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2" style={{color: 'goldenrod'}}>
                                        {product.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        <span style={{display: 'block'}}>
                                            Price: <span style={{
                                                fontFamily: 'helvetica neue',
                                                fontWeight: '400',
                                            }}>${parseFloat(product.price/100)}</span>
                                        </span>
                                        <span style={{display: 'block'}}>
                                            Category: <span style={{
                                                fontFamily: 'helvetica neue',
                                                fontWeight: '400',
                                            }}>{product.category}</span>
                                        </span>
                                        <span style={{display: 'block'}}>
                                            URL: <span style={{
                                                fontFamily: 'helvetica neue',
                                                fontWeight: '400',
                                            }}>{product.url}</span>
                                        </span>
                                        <span style={{display: 'block', marginBottom: '1rem'}}>
                                            Active: <span style={{
                                                fontFamily: 'helvetica neue',
                                                fontWeight: '400',
                                            }}>{product.active ? <ActiveIcon style={{verticalAlign: 'middle', fontSize: '1rem', color: 'forestgreen'}}/> : <InactiveIcon style={{verticalAlign: 'middle', fontSize: '1rem', color: 'red'}}/> }</span>
                                        </span>
                                        <span style={{
                                            display: 'block',
                                            fontFamily: 'helvetica neue',
                                            fontWeight: '400',
                                            overflow: 'hidden',
                                            width: '100%',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {product.description}
                                        </span>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>)})}
                </Grid>
            </FadeIn>

            <Dialog open={productFormVisible} onClose={clearProduct} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: 'goldenrod'}}/> : editing ? 'Edit Product' : 'Add Product'}</DialogTitle>
                {editing && <DialogContentText style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
                    Active: {product && product.active ? <ActiveIcon style={{verticalAlign: 'middle', fontSize: '1rem', color: 'forestgreen'}}/> : <InactiveIcon style={{verticalAlign: 'middle', fontSize: '1rem', color: 'red'}}/>}
                </DialogContentText>}
                <DialogContent>
                    <TextField
                        fullWidth
                        disabled={loading}
                        label="Name"
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        value={product && product.name}
                        onChange={(event) => setProduct({...product, name: event.target.value})}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        multiline
                        rows={7}
                        disabled={loading}
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        label="Description"
                        value={product && product.description}
                        onChange={(event) => setProduct({...product, description: event.target.value})}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        disabled={loading}
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        label="URL to Purchase"
                        value={product && product.url}
                        onChange={(event) => setProduct({...product, url: event.target.value})}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        select
                        label="Category"
                        variant="outlined"
                        disabled={loading}
                        value={product && product.category}
                        onChange={(event) => setProduct({...product, category: event.target.value})}
                        className={`${classes.inputDark} ${classes.blockInput}`}
                    >
                        {['cleaning', 'hair', 'other'].map((category) => { 
                            return (<MenuItem key={category} value={category}>{category}</MenuItem>)
                        })}
                    </TextField>
                    <TextField
                        disabled={loading}
                        type="number"
                        label="Price"
                        style={{marginRight: '1rem'}}
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        value={product && (product.price/100).toString()}
                        onChange={(event) => setProduct({...product, price: parseInt(event.target.value*100, 10)})}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <TextField
                        disabled={loading}
                        type="file"
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        helperText="Product Image (min. 500x500)"
                        onChange={(event) => setImage(event.target.files[0])}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />

                </DialogContent>
                <DialogActions style={{display: 'block', padding: '1rem 1.5rem 0'}}>
                    <Button fullWidth disabled={loading} onClick={() => editing ? updateProduct() : addProduct()} variant="contained" style={{backgroundColor: '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                        {editing ? 'Update' : 'Add'} Product
                    </Button>
                    <Button fullWidth disabled={loading} onClick={clearProduct} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginLeft: 0, marginBottom: '1rem'}}>
                        Cancel
                    </Button>
                    {editing && <Button fullWidth disabled={loading} onClick={() => updateProduct(true)} variant="contained" style={{backgroundColor: product.active ? 'red' : 'forestgreen', color: '#f5f5f5', textTransform: 'none', marginLeft: 0, marginBottom: '1rem'}}>
                        {product && product.active ? 'Deactivate' : 'Activate'}
                    </Button>}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default withStyles(GlobalStyle)(Products);