import { useState } from 'react';
import {
    Grid,
    Paper,
    Typography,
    TextField,
    Button,
    withStyles
} from '@material-ui/core';

import GlobalStyle from '../../style/Global';

function ForgotPassword({classes, setScene, setMessage}) {

    const [email, setEmail] = useState('');

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }

    return (
        <Grid item xs={12} style={{position: 'relative', paddingTop: '5rem'}}>
            <Paper style={{backgroundColor: 'transparent', boxShadow: 'none', marginBottom: '1rem'}}>
                
                <Typography
                    style={{color: '#f5f5f5', fontSize: '2rem', fontFamily: 'Helvetica', fontWeight: '200', lineHeight: 1, marginBottom: '0.25rem'}}
                >
                    Forgot Password
                </Typography>
                <Typography style={{color: '#f5f5f5', fontFamily: 'Helvetiva Neue', fontWeight: '100', marginBottom: '1rem'}}>
                    Have your password? <span onClick={() => setScene('log in')} style={{cursor: 'pointer', color: 'goldenrod', textDecoration: 'none'}}>Log In</span>
                </Typography>
                <TextField
                    label="email"
                    variant="outlined"
                    size="small"
                    className={classes.input}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    fullWidth
                />
                <Button
                    variant="contained"
                    onClick={() => {setEmail('');showFeedback("If an account exists a reset email will be sent", classes.success)}}
                    style={{backgroundColor: '#f5f5f5', color: '#222', marginRight: '1rem'}}
                >
                    Send Password Reset Email
                </Button>
            </Paper>

            <Typography variant="caption" style={{fontFamily: 'helvetica', fontWeight: '200', color: '#eee', lineHeight: 1}}>
                By logging in or signing up for The Cut Life services you are agreeing to our <a href='/legal' style={{color: 'goldenrod', textDecoration: 'none'}}>terms and conditions</a>.
            </Typography>
        </Grid>
    )
}
  
export default withStyles(GlobalStyle)(ForgotPassword);  