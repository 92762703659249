import { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Button,
    IconButton,
    InputAdornment,
    CircularProgress,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    withStyles
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddCircle';
import ActiveIcon from '@material-ui/icons/CheckCircle';
import InactiveIcon from '@material-ui/icons/Cancel';

import GlobalStyle from '../../style/Global';
import FadeIn from 'react-fade-in';


function Reviews ({
    classes,
    stylist,
    setMessage,
    DB,
    CDN,
}) {

    // const initialContentData = {
    //     caption: null,
    //     image: null,
    //     stylistId: stylist.id,
    //     type: 'image',
    //     tags: '',
    //     placeholderImage: 'https://firebasestorage.googleapis.com/v0/b/the-cut-life.appspot.com/o/placeholder.jpg?alt=media&token=b40c0e15-4984-4038-a7c4-578602bf7d7e',
    //     active: true
    // };

    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState([]);

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }
    
    const getReviews = () => {
        DB.collection('reviews').where('stylistId', '==', stylist.id).get()
        .then((reviewsSnapshot) => {
            const reviewsContainer = [];

            reviewsSnapshot.forEach((reviewDocument) => {
                const review = reviewDocument.data();
                review.id = reviewDocument.id;

                reviewsContainer.push(review);
            });

            setReviews(reviewsContainer);
            setLoading(false);
        })
    }


    useEffect(() => {
        getReviews();
    }, [])

    return (
        <div>
            <FadeIn>
                <Grid container item xs={12} style={{padding: '1rem'}}>
                    <Grid item xs={12} style={{position: 'relative', color: '#222', padding: '1rem'}}>
                        <Typography style={{
                            fontFamily: 'helvetica neue',
                            fontSize: '1.5rem',
                        }}>
                            Reviews
                        </Typography>
                        {/* <div style={{flexGrow: 1}}/>
                        <IconButton aria-label="add" onClick={() => setContentFormVisible(true)} style={{position: 'absolute', top: '30%', right: '1rem'}}>
                            <AddIcon style={{color: '#222'}}/>
                        </IconButton> */}
                    </Grid>
                    {reviews && reviews.length < 1 && <Typography style={{marginTop: '1rem', padding: '1rem', fontFamily: 'helvetica neue', fontSize: '1rem', fontWeight: '400'}}>You haven't received any reviews or ratings, yet.</Typography>}
                </Grid>
            </FadeIn>
        </div>
    )
}

export default withStyles(GlobalStyle)(Reviews);