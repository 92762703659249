import React from 'react';
import logo from '../assets/images/logo_light.png';
import {
    Button
} from '@material-ui/core';


export default function Legal({ setRoute }) {

    return (
        <div style={{padding: '2rem', color: '#f5f5f5', backgroundColor: '#222'}}>
          <div>
              <div style={{marginBottom: '2rem'}}>
                  <a href="/"><img src={logo} style={{cursor: 'pointer', maxHeight: '5rem', margin: '1rem'}} alt="logo" /></a>
                  <Button style={{float: 'right'}} variant="contained" href='/' >home</Button>
              </div>
              <div>
                  <p>
                      The Cut Life, Inc ("us", "we", or "our") operates the thecutlife.com website, The Cut Life app, and widget(s)(the "Service").
                      This page informs you of our terms and policies regarding the collection, use and disclosure of Personal Information when you use our Service.
                      We will not use or share your information with anyone except as described in this Privacy Policy.
                      We use your Personal Information for providing and improving the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. 
                  </p>
              </div>
  
              <div>
                  <h2 style={{marginTop: '3rem'}}>Terms of Use</h2>
                  <p>
                      By accessing the website at http://thecutlife.com or the The Cut Life app, you are agreeing to be bound by these terms of service, all applicable laws and regulations, 
                      and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited 
                      from using or accessing this site. The materials contained in this website or app are protected by applicable copyright and trademark law.
                  </p>
  
                  <h4 style={{fontWeight: '700'}}>Use License</h4>
                  <p>
                      Permission is granted to temporarily download one copy of the materials (information or software) on The Cut Life's website and app for personal, non-commercial transitory viewing only. 
                      This is the grant of a license, not a transfer of title, and under this license you may not: 
                      1 - modify or copy the materials 
                      2 - use the materials for any commercial purpose, or for any public display commercial or non-commercial 
                      3 - attempt to decompile or reverse engineer any software contained on Musterd, Inc.'s website 
                      4 - remove any copyright or other proprietary notations from the materials; or 
                      5 - transfer the materials to another person or "mirror" the materials on any other server. 
                      This license shall automatically terminate if you violate any of these restrictions and may be terminated by The Cut Life, Inc at any time. 
                      Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
                  </p>
  
                  <h4 style={{fontWeight: '700'}}>Disclaimer</h4>
                  <p>
                      We collect information that your browser or phone sends whenever you visit or use our Service ("Log Data"). 
                      This Log Data may include information such as your computer or phone's Internet Protocol ("IP") address, browser type, 
                      browser version, phone type, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.
                  </p>
  
                  <h4 style={{fontWeight: '700'}}>Limitations</h4>
                  <p>
                      In no event shall The Cut Life, Inc or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) 
                      arising out of the use or inability to use the materials on The Cut Life's website or app, even if The Cut Life, Inc or a The Cut Life, Inc authorized representative has been notified orally or 
                      in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, 
                      these limitations may not apply to you.
                  </p>
  
                  <h4 style={{fontWeight: '700'}}>Accuracy</h4>
                  <p>
                      The materials appearing on The Cut Life, Inc's website and app could include technical, typographical, or photographic errors. 
                      The Cut Life, Inc does not warrant that any of the materials on its website or app are accurate, complete or current. 
                      The Cut Life, Inc may make changes to the materials contained on its website or app at any time without notice. 
                      However The Cut Life, Inc does not make any commitment to update the materials.
                  </p>
  
                  <h4 style={{fontWeight: '700'}}>Links</h4>
                  <p>
                      The Cut Life, Inc has not reviewed all of the sites linked to its website and app and is not responsible for the contents of any such linked site. 
                      The inclusion of any link does not imply endorsement by The Cut Life, Inc of the site. Use of any such linked website is at the user's own risk.
                  </p>
  
                  <h4 style={{fontWeight: '700'}}>Modifications</h4>
                  <p>
                      The Cut Life, Inc may revise these terms of service or privacy policy for its website, apps, widgets, and et cetera at any time without notice. 
                      By using this website or app you are agreeing to be bound by the then current version of these terms of service.
                  </p>
  
  
                  <h4 style={{fontWeight: '700'}}>Governing Law</h4>
                  <p>
                      These terms and conditions are governed by and construed in accordance with the laws of Georgia and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                  </p>
  
            </div>
  
            <div>
  
              <h2 style={{marginTop: '3rem'}}>Privacy Policy</h2>
  
              <h4 style={{fontWeight: '700'}}>Information Collection and Use</h4>
              <p>
                  While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. 
                  Personally identifiable information may include, but is not limited to, your name, phone number, other information ("Personal Information").
              </p>
  
              <h4 style={{fontWeight: '700'}}>Log Data</h4>
              <p>
                  We collect information that your browser or phone sends whenever you visit our Service ("Log Data"). 
                  This Log Data may include information such as your computer or phone's Internet Protocol ("IP") address, browser type, 
                  browser version, phone type, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics, and more.
              </p>
  
              <h4 style={{fontWeight: '700'}}>Cookies</h4>
              <p>
                  Cookies are files with small amount of data, which may include an anonymous unique identifier. 
                  Cookies are sent to your browser from a web site or app and stored on your computer's hard drive.
                  We use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. 
                  However, if you do not accept cookies, you may not be able to use some portions of our Service.
              </p>
  
              <h4 style={{fontWeight: '700'}}>Security</h4>
              <p>
                  The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. 
                  While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
              </p>
  
              <h4 style={{fontWeight: '700'}}>Age Restriction and Privacy</h4>
              <p>
                  Our Service is not for anyone under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from children under 18. 
                  If you are a parent or guardian and you are aware that your Children has provided us with Personal Information, please contact us. 
                  If we discover that a Children under 18 has provided us with Personal Information, we will delete such information from our servers immediately.
              </p>
            </div>
          </div>
        </div>
    );
}
