import {
    DataGrid
} from '@material-ui/data-grid';
import moment from 'moment';


export default function AppointmentList ({
    appointments
}) {

    const durationStrings = [
        null,
        '15 Minutes',
        '30 Minutes',
        '45 Minutes',
        '1 Hour',
        '1 Hour 15 Minutes',
        '1 Hour 30 Minutes',
        '1 Hour 45 Minutes',
        '2 Hours',
        '2 Hours 15 Minutes',
        '2 Hours 30 Minutes',
        '2 Hours 45 Minutes',
        '3 Hours',
        '3 Hours 15 Minutes',
        '3 Hours 30 Minutes',
        '3 Hours 45 Minutes',
        '4 Hours+',
    ];

    const columns = [
        {field: 'name', headerName: 'Name', valueGetter: (params) => 
            `${params.getValue('client').firstName} ${params.getValue('client').lastName}`,
            width: 200
        },
        {field: 'startTimeDate', headerName: 'Time & Date', valueGetter: (params) => 
            `${moment(params.getValue('startTimeDate').timestamp.toDate()).format('MMM Do, YYYY  |  h:mma')} (${moment(params.getValue('startTimeDate').timestamp.toDate()).fromNow()})`,
            width: 350
        },
        {field: 'service', headerName: 'Service', valueGetter: (params) => 
            `${params.getValue('service').name} (${durationStrings[params.getValue('service').duration]})`,
            width: 300
        }
    ]

    return <DataGrid rows={appointments} columns={columns} pageSize={5}/>
}