import { useState, useEffect } from 'react';
import GlobalStyle from '../../style/Global';
import FadeIn from 'react-fade-in';
import AddIcon from '@material-ui/icons/AddCircle';
import {
    Grid,
    Typography,
    Button,
    IconButton,
    withStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    MenuItem,
    CircularProgress,
    Paper
} from '@material-ui/core';
import {
    DataGrid
} from '@material-ui/data-grid';
import moment from 'moment';


function Appointments({
    firebase,
    DB,
    stylist,
    setMessage,
    classes
}) {

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }

    const durationStrings = [
        null,
        '15 Minutes',
        '30 Minutes',
        '45 Minutes',
        '1 Hour',
        '1 Hour 15 Minutes',
        '1 Hour 30 Minutes',
        '1 Hour 45 Minutes',
        '2 Hours',
        '2 Hours 15 Minutes',
        '2 Hours 30 Minutes',
        '2 Hours 45 Minutes',
        '3 Hours',
        '3 Hours 15 Minutes',
        '3 Hours 30 Minutes',
        '3 Hours 45 Minutes',
        '4 Hours+',
    ];

    const columns = [
        {field: 'name', headerName: 'Name', valueGetter: (params) => 
            `${params.getValue('client').firstName} ${params.getValue('client').lastName}`,
            width: 200
        },
        {field: 'startTimeDate', headerName: 'Time & Date', valueGetter: (params) => 
            `${moment(params.getValue('startTimeDate').timestamp.toDate()).format('MMM Do, YYYY  |  h:mma')} (${moment(params.getValue('startTimeDate').timestamp.toDate()).fromNow()})`,
            width: 350
        },
        {field: 'service', headerName: 'Service', valueGetter: (params) => 
            `${params.getValue('service').name} (${durationStrings[params.getValue('service').duration]})`,
            width: 300
        }
    ]

    const months = [
        {name: 'January', value: 0},
        {name: 'February', value: 1},
        {name: 'March', value: 2},
        {name: 'April', value: 3},
        {name: 'May', value: 4},
        {name: 'June', value: 5},
        {name: 'July', value: 6},
        {name: 'August', value: 7},
        {name: 'September', value: 8},
        {name: 'October', value: 9},
        {name: 'November', value: 10},
        {name: 'December', value: 11}
    ];

    const thisYear = moment().format('YYYY');

    const initialAppointmentData = {
        startTimeDate: {
            hour: 13,
            minute: 0,
            month: 0,
            date: 1,
            year: thisYear
        },
        client: {
            id: 0
        },
        service: {
            id: 0
        },
        stylist
    };

    const years = [
        thisYear,
        thisYear + 1
    ];

    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [appointment, setAppointment] = useState(initialAppointmentData);
    const [appointments, setAppointments] = useState([]);
    const [appointmentFormVisible, setAppointmentFormVisible] = useState(false);
    const [clients, setClients] = useState({});
    const [services, setServices] = useState({});

    const getServices = () => {
        DB.collection('services').where('stylistId', '==', stylist.id).get()
        .then((servicesSnapshot) => {
            const servicesContainer = {};

            servicesSnapshot.forEach((serviceDocument) => {
                const service = serviceDocument.data();
                service.id = serviceDocument.id;

                servicesContainer[service.id] = service;
            })

            setServices(servicesContainer);
        })
        .catch((error) => {
            showFeedback(error.message, classes.error);
        })
    };

    const getClients = () => {
        DB.collection('clients').where('stylistId', '==', stylist.id).get()
        .then((clientsSnapshot) => {
            const clientsContainer = {};

            clientsSnapshot.forEach((clientDocument) => {
                const client = clientDocument.data();
                client.id = clientDocument.id;

                clientsContainer[client.id] = client;
            })

            setClients(clientsContainer);
        })
        .catch((error) => {
            showFeedback(error.message, classes.error);
        })
    };

    const addAppointment = () => {
        if (!appointment.stylist || !appointment.client) {
            return showFeedback('All fields required', classes.error);
        }

        setLoading(true);
        const timeDate = new Date(`${appointment.startTimeDate.year}-${appointment.startTimeDate.month < 10 ? '0'+(appointment.startTimeDate.month+1) : (appointment.startTimeDate.month+1)}-${appointment.startTimeDate.date < 10 ? '0'+appointment.startTimeDate.date : appointment.startTimeDate.date}T${appointment.startTimeDate.hour < 10 ? '0'+appointment.startTimeDate.hour : appointment.startTimeDate.hour}:${appointment.startTimeDate.minute < 10 ? '0'+appointment.startTimeDate.minute : appointment.startTimeDate.minute}:00`);

        if (editing) {
            return DB.collection('appointments').doc(appointment.id).delete()
            .then(() => {
                getAppointments();
                setAppointmentFormVisible(false);
                setAppointment(initialAppointmentData);
                setEditing(false);
                showFeedback('Appointment cancelled, successfully!', classes.success);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                showFeedback(error.message, classes.error);
            })
        }

        DB.collection('appointments').add({
            ...appointment,
            startTimeDate: {
                ...appointment.startTimeDate,
                timestamp: timeDate,
            },
            createdAt: firebase.firestore.Timestamp.now()
        })
        .then(() => {
            getAppointments();
            setAppointmentFormVisible(false);
        })
        .catch((error) => {
            showFeedback(error.message, classes.error);
            setLoading(false);
        })
    };

    const getAppointments = () => {
        DB.collection('appointments').where('stylist.id', '==', stylist.id).orderBy('startTimeDate.timestamp', 'desc').get()
        .then((appointmentsSnapshot) => {
            const appointmentsContainer = [];

            appointmentsSnapshot.forEach((appointmentDocument) => {
                const appointment = appointmentDocument.data();
                appointment.id = appointmentDocument.id;
                appointmentsContainer.push(appointment);
            })

            setAppointments(appointmentsContainer);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error.message)
            showFeedback(error.message, classes.error);
        })
    }

    useEffect(() => {
        getServices();
        getClients();
        getAppointments();
    }, []);

    return (
        <div>
            <FadeIn>
                <Grid container item xs={12} style={{padding: '1rem'}}>
                    <Grid item xs={12} style={{position: 'relative', color: '#222', padding: '1rem'}}>
                        <Typography style={{
                            fontFamily: 'helvetica neue',
                            fontSize: '1.5rem',
                        }}>
                            Appointments
                        </Typography>
                        <div style={{flexGrow: 1}}/>
                        <IconButton onClick={() => Object.keys(clients).length > 0 ? setAppointmentFormVisible(true) : showFeedback("You don't have any clients, yet.", classes.info)} aria-label="add appointment" style={{position: 'absolute', top: '30%', right: '1rem'}}>
                            <AddIcon style={{color: '#222'}}/>
                        </IconButton>
                    </Grid>

                    <Grid item xs={12} style={{height: '80vh', padding: '1rem'}}>
                        {loading && <div style={{textAlign: 'center'}}><CircularProgress size={25} style={{marginTop: '5rem', alignSelf: 'center', color: '#444'}}/></div>}
                        {!loading && appointments.length < 1 && <Typography style={{marginTop: '1rem', fontFamily: 'helvetica neue', fontSize: '1rem', fontWeight: '400'}}>No appointments scheduled, yet.</Typography>}
                        {!loading && appointments.length > 0 && <Paper style={{height: '100%'}} className={classes.tcl}>
                            {!loading && appointments.length > 0 && <DataGrid rows={appointments} columns={columns} pageSize={25} onRowClick={(param) => {setAppointment(param.data); setEditing(true); setAppointmentFormVisible(true)}}/>}
                        </Paper>}
                    </Grid>
                </Grid>
            </FadeIn>


            <Dialog open={appointmentFormVisible} onClose={() => { setAppointmentFormVisible(false); setAppointment(initialAppointmentData); setEditing(false)}} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: '#444'}}/> : editing ? 'Appointment' : 'Add Appointment'}</DialogTitle>
                {/* <DialogContentText style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
                    .......
                </DialogContentText> */}
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                disabled={loading || editing}
                                label="Service"
                                variant="outlined"
                                value={appointment && appointment.service.id}
                                onChange={(event) => setAppointment({...appointment, service: services[event.target.value]})}
                                className={`${classes.inputDark} ${classes.blockInput}`}
                            >
                                {services && Object.keys(services).map((serviceId) => { return (<MenuItem key={serviceId} value={serviceId}>{services[serviceId].name}</MenuItem>)})}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                disabled={loading || editing}
                                label="Client"
                                variant="outlined"
                                value={appointment && appointment.client.id}
                                onChange={(event) => setAppointment({...appointment, client: clients[event.target.value]})}
                                className={`${classes.inputDark} ${classes.blockInput}`}
                            >
                                {clients && Object.keys(clients).map((clientId) => { return (<MenuItem key={clientId} value={clientId}>{`${clients[clientId].firstName} ${clients[clientId].lastName}`}</MenuItem>)})}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                select
                                disabled={loading || editing}
                                label={`Hour ${appointment.startTimeDate.hour > 12 ? '(PM)' : '(AM)'}`}
                                variant="outlined"
                                style={{marginTop: '1rem'}}
                                value={appointment && appointment.startTimeDate.hour}
                                onChange={(event) => setAppointment({...appointment, startTimeDate: {...appointment.startTimeDate, hour: event.target.value}})}
                                className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                            >
                                {[6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                select
                                disabled={loading || editing}
                                label="Minute"
                                variant="outlined"
                                style={{marginTop: '1rem'}}
                                value={appointment && appointment.startTimeDate.minute}
                                onChange={(event) => setAppointment({...appointment, startTimeDate: {...appointment.startTimeDate, minute: event.target.value}})}
                                className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                            >
                                {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                select
                                disabled={loading || editing}
                                label="Month"
                                variant="outlined"
                                style={{marginTop: '1rem'}}
                                value={appointment && appointment.startTimeDate.month}
                                onChange={(event) => setAppointment({...appointment, startTimeDate: {...appointment.startTimeDate, month: event.target.value}})}
                                className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                            >
                                {months.map((month) => { return (<MenuItem key={month.value} value={month.value}>{month.name}</MenuItem>)})}
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                select
                                disabled={loading || editing}
                                label="Date"
                                variant="outlined"
                                style={{marginTop: '1rem'}}
                                value={appointment && appointment.startTimeDate.date}
                                onChange={(event) => setAppointment({...appointment, startTimeDate: {...appointment.startTimeDate, date: event.target.value}})}
                                className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                            >
                                {[...Array(32).keys()].map((date) => { return date === 0 ? null : (<MenuItem key={date} value={date}>{date}</MenuItem>)})}
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                select
                                disabled={loading || editing}
                                label="Year"
                                variant="outlined"
                                style={{marginTop: '1rem'}}
                                value={appointment && appointment.startTimeDate.year}
                                onChange={(event) => setAppointment({...appointment, startTimeDate: {...appointment.startTimeDate, year: event.target.value}})}
                                className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                            >
                                {[thisYear, `${parseInt(thisYear) + 1}`].map((year) => { return (<MenuItem key={year} value={year}>{year}</MenuItem>)})}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => {setAppointmentFormVisible(false); setAppointment(initialAppointmentData)}} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem'}}>
                        {editing ? 'Close' : 'Cancel'}
                    </Button>
                    <Button disabled={loading} onClick={addAppointment} variant="contained" style={{backgroundColor: editing ? 'red' : '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                        {editing ? 'Cancel Appointment' : 'Add Appointment'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default withStyles(GlobalStyle)(Appointments);