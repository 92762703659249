import { useState } from 'react';
import {
    Grid,
    Paper,
    Typography,
    TextField,
    Button,
    withStyles
} from '@material-ui/core';

import GlobalStyle from '../../style/Global';

function LogIn({AUTH, DB, classes, setRoute, setScene, setMessage, setStylist}) {
    const [loading, setLoading] = useState(null);
    const [emailAddress, setEmailAddress] = useState("");
    const [securePassword, setSecurePassword] = useState("");

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }

    const logIn = () => {
        setLoading(true);
    
        if (!emailAddress || !securePassword) {
            showFeedback("All fields required", classes.error);
            setLoading(false);
        } else {
            AUTH.signInWithEmailAndPassword(emailAddress, securePassword)
            .then((authenticatedUser) => {
                getStylist(authenticatedUser);
            })
            .catch((error) => {
                showFeedback(error.message, classes.error)    
                setLoading(false);
            })
        }
    }

    const getStylist = (authenticatedUserId) => {
        DB.collection('stylists').doc(authenticatedUserId.user.uid).get()
        .then((stylistDocument) => {
            const stylist = stylistDocument.data();
            stylist.id = stylistDocument.id;

            setStylist(stylist);
            setRoute('/index');
        })
        .catch((error) => {
            showFeedback(error.message, classes.error)
            setLoading(false);
        })
    }


    return (
        <Grid item xs={12} style={{position: 'relative', paddingTop: '5rem'}}>
            <Paper style={{backgroundColor: 'transparent', boxShadow: 'none', marginBottom: '1rem'}}>
                
                <Typography
                    style={{color: '#f5f5f5', fontSize: '2rem', fontFamily: 'Helvetica', fontWeight: '200', lineHeight: 1, marginBottom: '1rem'}}
                >
                    Log In
                </Typography>
                <TextField
                    label="email"
                    variant="outlined"
                    size="small"
                    className={classes.input}
                    value={emailAddress}
                    onChange={(event) => setEmailAddress(event.target.value)}
                    fullWidth
                />
                <TextField
                    label="password"
                    type="password"
                    variant="outlined"
                    size="small"
                    className={classes.input}
                    value={securePassword}
                    onChange={(event) => setSecurePassword(event.target.value)}
                    fullWidth
                />
                <Button
                    variant="contained"
                    onClick={logIn}
                    style={{backgroundColor: '#f5f5f5', color: '#222', marginRight: '1rem'}}
                >
                    Log In
                </Button>
                <Button onClick={() => setScene('forgot password')} style={{color: '#f5f5f5', textTransform: 'none'}}>
                    Forgot Password
                </Button>
            </Paper>

            <Typography variant="caption" style={{fontFamily: 'helvetica', fontWeight: '200', color: '#eee', lineHeight: 1}}>
                By logging in or signing up for The Cut Life services you are agreeing to our <a href='/legal' style={{color: 'goldenrod', textDecoration: 'none'}}>terms and conditions</a>.
            </Typography>
        </Grid>
    )
}
  
export default withStyles(GlobalStyle)(LogIn);  