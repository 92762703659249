import { useState, useEffect } from 'react';
import {
    Grid,
    Paper,
    Typography,
    SnackbarContent,
    withStyles
} from '@material-ui/core';

import Overview from './Overview';
import Messages from './Messages';
import Appointments from './Appointments';
import Schedule from './Schedule';
import Orders from './Orders';
import Products from './Products';
import Services from './Services';
import Content from './Content';
import Clients from './Clients';
import Reviews from './Reviews';
import Settings from './Settings';
import Courses from './Courses';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ListIcon from '@material-ui/icons/List';
import EmailIcon from '@material-ui/icons/Email';
import EventIcon from '@material-ui/icons/Event';
import GroupIcon from '@material-ui/icons/PeopleAlt';
import ReviewIcon from'@material-ui/icons/Grade';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SettingsIcon from '@material-ui/icons/Settings';
import SchoolIcon from '@material-ui/icons/School';
import CartIcon from '@material-ui/icons/ShoppingCart';
import ContentIcon from '@material-ui/icons/Videocam';
import LogOutIcon from '@material-ui/icons/ExitToApp';

import Nav from '../../components/Nav';
import AppointmentList from '../../components/AppointmentList';
import GlobalStyle from '../../style/Global';
import logo from '../../assets/images/logo_light.png';
import FadeIn from 'react-fade-in';
import moment  from 'moment';


function Dashboard({
    firebase,
    AUTH,
    DB,
    CDN,
    classes,
    setRoute,
    setScene,
    initialDashboardScene
}) {
    const [dashboardScene, setDashboardScene] = useState(initialDashboardScene);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [stylist, setStylist] = useState(null);

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }

    const getStylist = () => {
        if (AUTH.currentUser) {
            DB.collection('stylists').doc(AUTH.currentUser.uid).get()
            .then((stylistDocument) => {
                const stylist = stylistDocument.data();
                stylist.id = stylistDocument.id;
    
                setStylist(stylist);
                setLoading(false);
            })
            .catch((error) => {
                showFeedback(error.message, classes.error);
                setLoading(false);
            })
        } else {
            window.location = '/';
        }
    }

    useEffect(() => {
        getStylist();
    }, [])

    return (
        <Grid container item xs={12} md={12} className={classes.root}>
            <div className={classes.mobile} style={{position: 'fixed', zIndex: 999999999999999, color: '#f5f5f5', top: 0, left: 0, width: '100vw', height: '100vh', background: '#222', alignItems: 'center', textAlign: 'center'}}>
                <img alt="logo" src={logo} style={{height: '5rem', margin: '1rem', marginTop: '3rem'}}/>
                <Typography style={{padding: '1rem', lineHeight: 1.5, fontFamily: 'helvetica neue', fontWeight: '200', fontSize: '1.25rem'}}>Please use a desktop device for now. A mobile-friendly version will be available soon so that you can keep track of things on the go :)</Typography>
            </div>
            {message && <FadeIn><SnackbarContent
                className={message.style}
                message={message.content}
            /></FadeIn>}
            <Grid item xs={12} md={2} style={{backgroundColor: '#222'}} className={classes.sidebarNav}>
                <Nav bgColor={'#222'} currentScene={dashboardScene} setDashboardScene={setDashboardScene} links={[
                    {title: 'overview', icon: <DashboardIcon/>},
                    {title: 'messages', icon: <EmailIcon/>},
                    {title: 'appointments', icon: <EventIcon/>},
                    {title: 'schedule', icon: <ScheduleIcon/>},
                    {title: 'services', icon: <ListIcon/>},
                    {title: 'products', icon: <CartIcon/>},
                    {title: 'content', icon: <ContentIcon/>},
                    {title: 'clients', icon: <GroupIcon/>},
                    {title: 'reviews', icon: <ReviewIcon/>},
                    {title: 'classes', icon: <SchoolIcon/>},
                    {title: 'settings', icon: <SettingsIcon/>},
                    {title: 'log out', icon: <LogOutIcon/>}
                ]}/>
            </Grid>
            {!loading && <Grid item md={10} style={{backgroundColor: '#f5f5f5'}}>
                {dashboardScene === 'overview' && <Overview DB={DB} stylist={stylist} classes={classes} setMessage={setMessage}/>}
                {dashboardScene === 'messages' && <Messages firebase={firebase} DB={DB} setMessage={setMessage} classes={classes} stylist={stylist} CDN={CDN}/>}
                {dashboardScene === 'appointments' && <Appointments firebase={firebase} DB={DB} setMessage={setMessage} classes={classes} stylist={stylist}/>}
                {dashboardScene === 'schedule' && <Schedule DB={DB} setMessage={setMessage} classes={classes} stylist={stylist}/>}
                {/* {dashboardScene === 'orders' && <Orders stylist={stylist}/>} */}
                {dashboardScene === 'products' && <Products DB={DB} setMessage={setMessage} classes={classes} stylist={stylist} CDN={CDN}/>}
                {dashboardScene === 'services' && <Services DB={DB} setMessage={setMessage} classes={classes} stylist={stylist} CDN={CDN}/>}
                {dashboardScene === 'content' && <Content DB={DB} setMessage={setMessage} classes={classes} stylist={stylist} CDN={CDN}/>}
                {dashboardScene === 'clients' && <Clients DB={DB} setMessage={setMessage} classes={classes} stylist={stylist}/>}
                {dashboardScene === 'classes' && <Courses DB={DB} setMessage={setMessage} classes={classes} stylist={stylist} CDN={CDN}/>}
                {dashboardScene === 'reviews' && <Reviews DB={DB} setMessage={setMessage} classes={classes} stylist={stylist}/>}
                {dashboardScene === 'settings' && <Settings DB={DB} setMessage={setMessage} classes={classes} stylist={stylist}/>}
            </Grid>}
        </Grid>
    )
}
  
export default withStyles(GlobalStyle)(Dashboard);  