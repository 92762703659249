import { useState } from 'react';
import GlobalStyle from '../../style/Global';
import FadeIn from 'react-fade-in';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Close';
import DownIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/ExpandLess';
import CalendarIcon from '@material-ui/icons/Today';
import ClockIcon from '@material-ui/icons/Alarm';
import LunchIcon from '@material-ui/icons/Restaurant';
import moment from 'moment';
import {
    Grid,
    Typography,
    Button,
    IconButton,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Paper,
    Chip,
    Checkbox,
    CircularProgress,
    withStyles
} from '@material-ui/core';


function Schedule({
    classes,
    DB,
    stylist,
    setMessage
}) {

    let initialScheduleStep = 'cta';

    if (stylist.regularSchedule) {
        initialScheduleStep = 'breaks';
    }

    if (stylist.breakSchedule) {
        initialScheduleStep = 'holidays';
    }

    if (stylist.holidaySchedule) {
        initialScheduleStep = 'complete';
    }

    const [loading, setLoading] = useState(false);
    const [scheduleStep, setScheduleStep] = useState(initialScheduleStep);

    const [sundayScheduleEditable, setSundayScheduleEditable] = useState(false);
    const [sundayBreakEditable, setSundayBreakEditable] = useState(false);
    
    const [mondayScheduleEditable, setMondayScheduleEditable] = useState(false);
    const [mondayBreakEditable, setMondayBreakEditable] = useState(false);
    
    const [tuesdayScheduleEditable, setTuesdayScheduleEditable] = useState(false);
    const [tuesdayBreakEditable, setTuesdayBreakEditable] = useState(false);
    
    const [wednesdayScheduleEditable, setWednesdayScheduleEditable] = useState(false);
    const [wednesdayBreakEditable, setWednesdayBreakEditable] = useState(false);
    
    const [thursdayScheduleEditable, setThursdayScheduleEditable] = useState(false);
    const [thursdayBreakEditable, setThursdayBreakEditable] = useState(false);
    
    const [fridayScheduleEditable, setFridayScheduleEditable] = useState(false);
    const [fridayBreakEditable, setFridayBreakEditable] = useState(false);
    
    const [saturdayScheduleEditable, setSaturdayScheduleEditable] = useState(false);
    const [saturdayBreakEditable, setSaturdayBreakEditable] = useState(false);
    
    const initialRegularSchedule = stylist.regularSchedule ? stylist.regularSchedule : {open: {hour: 9, minute: 0}, close: {hour: 17, minute: 0}, isClosed: false};
    const initialBreakSchedule = stylist.breakSchedule ? stylist.breakSchedule : {start: {hour: 12, minute: 30}, end: {hour: 13, minute: 30}};

    const [sundaySchedule, setSundaySchedule] = useState(stylist.regularSchedule ? stylist.regularSchedule.sunday : initialRegularSchedule);
    const [sundayBreak, setSundayBreak] = useState(stylist.breakSchedule ? stylist.breakSchedule.sunday : initialBreakSchedule);
 
    const [mondaySchedule, setMondaySchedule] = useState(stylist.regularSchedule ? stylist.regularSchedule.monday : initialRegularSchedule);
    const [mondayBreak, setMondayBreak] = useState(stylist.breakSchedule ? stylist.breakSchedule.monday : initialBreakSchedule);
 
    const [tuesdaySchedule, setTuesdaySchedule] = useState(stylist.regularSchedule ? stylist.regularSchedule.tuesday : initialRegularSchedule);
    const [tuesdayBreak, setTuesdayBreak] = useState(stylist.breakSchedule ? stylist.breakSchedule.tuesday : initialBreakSchedule);

    const [wednesdaySchedule, setWednesdaySchedule] = useState(stylist.regularSchedule ? stylist.regularSchedule.wednesday : initialRegularSchedule);
    const [wednesdayBreak, setWednesdayBreak] = useState(stylist.breakSchedule ? stylist.breakSchedule.wednesday : initialBreakSchedule);
 
    const [thursdaySchedule, setThursdaySchedule] = useState(stylist.regularSchedule ? stylist.regularSchedule.thursday : initialRegularSchedule);
    const [thursdayBreak, setThursdayBreak] = useState(stylist.breakSchedule ? stylist.breakSchedule.thursday : initialBreakSchedule);
 
    const [fridaySchedule, setFridaySchedule] = useState(stylist.regularSchedule ? stylist.regularSchedule.friday : initialRegularSchedule);
    const [fridayBreak, setFridayBreak] = useState(stylist.breakSchedule ? stylist.breakSchedule.friday : initialBreakSchedule);
 
    const [saturdaySchedule, setSaturdaySchedule] = useState(stylist.regularSchedule ? stylist.regularSchedule.saturday : initialRegularSchedule);
    const [saturdayBreak, setSaturdayBreak] = useState(stylist.breakSchedule ? stylist.breakSchedule.saturday : initialBreakSchedule);

    const [holidays, setHolidays] = useState(stylist.holidaySchedule ? stylist.holidaySchedule : []);
    const [holidayName, setHolidayName] = useState('');
    const [holidayMonth, setHolidayMonth] = useState(0);
    const [holidayDate, setHolidayDate] = useState(1);
    const [selectedHoliday, setSelectedHoliday] = useState(null);

    const [regularScheduleConfirmationVisible, setRegularScheduleConfirmationVisible] = useState(false);
    const [breakScheduleConfirmationVisible, setBreakScheduleConfirmationVisible] = useState(false);
    const [createHolidayModalVisible, setCreateHolidayModalVisible] = useState(false);
    const [deleteHolidayConfirmationVisible, setDeleteHolidayConfirmationVisible] = useState(false);
    const [editScheduleDialogVisible, setEditScheduleDialogVisible] = useState(false);
  
    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }

    const setRegularSchedule = () => {
        setLoading(true);

        const newRegularSchedule = {
            sunday: sundaySchedule,
            monday: mondaySchedule,
            tuesday: tuesdaySchedule,
            wednesday: wednesdaySchedule,
            thursday: thursdaySchedule,
            friday: fridaySchedule,
            saturday: saturdaySchedule
        };

        DB.collection('stylists').doc(stylist.id).update({
            regularSchedule: newRegularSchedule
        })
        .then(() => {
            stylist.regularSchedule = newRegularSchedule;
            if (stylist.regularSchedule && stylist.breakSchedule && stylist.holidaySchedule) {
                setScheduleStep('complete');
            } else {
                setScheduleStep('breaks');
            }
            setRegularScheduleConfirmationVisible(false);
            showFeedback('Regular schedule set successfully!', classes.success);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error.message, classes.error);
        })
    }

    const setBreakSchedule = () => {
        setLoading(true);

        const newBreakSchedule = {
            sunday: sundayBreak,
            monday: mondayBreak,
            tuesday: tuesdayBreak,
            wednesday: wednesdayBreak,
            thursday: thursdayBreak,
            friday: fridayBreak,
            saturday: saturdayBreak
        };

        DB.collection('stylists').doc(stylist.id).update({
            breakSchedule: newBreakSchedule
        })
        .then(() => {
            stylist.breakSchedule = newBreakSchedule;
            if (stylist.regularSchedule && stylist.breakSchedule && stylist.holidaySchedule) {
                setScheduleStep('complete');
            } else {
                setScheduleStep('holidays');
            }
            setBreakScheduleConfirmationVisible(false);
            showFeedback('Break schedule set successfully!', classes.success);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error.message, classes.error);
        })
    }

    const addHoliday = () => {
        if (holidayMonth === 1 && holidayDate > 28) {
            showFeedback('There are only 28 days in February', classes.error);
        } else if ((holidayMonth === 3 || holidayMonth === 5 || holidayMonth === 8 || holidayMonth === 10) && holidayDate > 30) {
            showFeedback('There are only 30 days in this month', classes.error);
        } else {
            setLoading(true);
            setHolidays([...holidays, {name: holidayName, month: holidayMonth, date: holidayDate}]);
            setHolidayName('');
            setHolidayMonth(0);
            setHolidayDate(1);
            setCreateHolidayModalVisible(false);
            setLoading(false);
        }
    }

    const deleteHoliday = () => {
        setLoading(true);
        let holidayContainer = holidays;

        let holidayToDelete = holidayContainer.indexOf(selectedHoliday);

        holidayContainer.splice(holidayToDelete, 1);

        setHolidays(holidayContainer);
        setDeleteHolidayConfirmationVisible(false);
        setSelectedHoliday(null);
        setLoading(false);
    }

    const setHolidaySchedule = () => {
        setLoading(true);

        DB.collection('stylists').doc(stylist.id).update({
            holidaySchedule: holidays
        })
        .then(() => {
            stylist.holidaySchedule = holidays;
            setScheduleStep('complete');
            showFeedback('Holiday schedule set successfully!', classes.success);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error.message, classes.error);
        })
    }


    return (
        <div>
            <FadeIn>
                <Grid container item xs={12} style={{padding: '1rem'}}>
                    <Grid item xs={12} style={{display: 'flex', color: '#222', padding: '1rem'}}>
                        <Typography style={{
                            fontFamily: 'helvetica neue',
                            fontSize: '1.5rem',
                        }}>
                            Schedule
                        </Typography>
                        <div style={{flexGrow: 1}}/>
                        {scheduleStep === 'complete' && <IconButton onClick={() => setEditScheduleDialogVisible(true)} aria-label="edit schedule" style={{textTransform: 'none'}}>
                            <EditIcon style={{color: '#444'}}/> 
                        </IconButton>}
                    </Grid>

                    {/* SCHEDULE COMPLETE */}
                    {scheduleStep === 'complete' && <Grid item container xs={12} style={{padding: '1rem'}} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="caption">Daily</Typography>
                        </Grid>
                        {stylist.regularSchedule && ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day) => {return (<Grid key={day} item xs={12} md={3}>
                            <Paper elevation={2} style={{minHeight: '8rem', padding: '1rem'}}>
                                <Typography style={{fontFamily: 'helvetica neue', fontSize: '1rem', fontWeight: '700', marginBottom: '0.5rem', textTransform: 'capitalize'}}>{day}</Typography>
                                <hr/>
                                {!stylist.regularSchedule[day].isClosed && <div>
                                    <div><Typography variant="caption">Open: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${stylist.regularSchedule[day].open.hour > 12 ? stylist.regularSchedule[day].open.hour - 12 : stylist.regularSchedule[day].open.hour}:${stylist.regularSchedule[day].open.minute === 0 ? '00' : stylist.regularSchedule[day].open.minute}${stylist.regularSchedule[day].open.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></div>
                                    {stylist.breakSchedule && <div><Typography variant="caption">Break (start): <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${stylist.breakSchedule[day].start.hour > 12 ? stylist.breakSchedule[day].start.hour - 12 : stylist.breakSchedule[day].start.hour}:${stylist.breakSchedule[day].start.minute === 0 ? '00' : stylist.breakSchedule[day].start.minute}${stylist.breakSchedule[day].start.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></div>}
                                    {stylist.breakSchedule && <div><Typography variant="caption">Break (end): <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${stylist.breakSchedule[day].end.hour > 12 ? stylist.breakSchedule[day].end.hour - 12 : stylist.breakSchedule[day].end.hour}:${stylist.breakSchedule[day].end.minute === 0 ? '00' : stylist.breakSchedule[day].end.minute}${stylist.breakSchedule[day].end.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></div>}
                                    <div><Typography variant="caption">Close: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${stylist.regularSchedule[day].close.hour > 12 ? stylist.regularSchedule[day].close.hour - 12 : stylist.regularSchedule[day].close.hour}:${stylist.regularSchedule[day].close.minute === 0 ? '00' : stylist.regularSchedule[day].close.minute}${stylist.regularSchedule[day].close.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></div>
                                </div>}
                                {stylist.regularSchedule[day].isClosed && <Typography style={{fontFamily: 'helvetica neue', fontSize: '1rem', fontWeight: '200', textTransform: 'capitalize'}}>closed</Typography>}
                            </Paper>
                        </Grid>)})}

                        <Grid item xs={12}>
                            <Typography variant="caption">Holidays</Typography>
                            {stylist.holidaySchedule.length < 1 && <Typography style={{marginTop: '1rem', fontFamily: 'helvetica neue', fontSize: '1rem', fontWeight: '400'}}>No holidays scheduled</Typography>}
                        </Grid>
                        {stylist.holidaySchedule.length > 0 && stylist.holidaySchedule.map((holiday) => {return (<Grid key={holiday.name} item xs={12} md={3}>
                            <Paper elevation={2} style={{padding: '1rem'}}>
                                <Typography style={{fontFamily: 'helvetica neue', fontSize: '1rem', fontWeight: '700', marginBottom: '0.5rem', textTransform: 'capitalize'}}>{holiday.name}</Typography>
                                <hr/>
                                <Typography variant="caption" style={{fontFamily: 'helvetica neue', fontWeight: '400'}}>{moment().month(holiday.month).format('MMM')} {moment().date(holiday.date).format('Do')}</Typography>
                            </Paper>
                        </Grid>)})}
                    </Grid>}

                    {/* 1) CREATE SCHEDULE CTA */}
                    {scheduleStep === 'cta' && <Grid item xs={12} style={{textAlign: 'center', paddingTop: '10rem'}}>
                        <CalendarIcon style={{fontSize: '14rem', color: '#ccc'}}/>
                        <Typography style={{fontFamily: 'helvetica neue', fontSize: '2rem', fontWeight: '200', marginBottom: '0.5rem'}}>Create Your Schedule</Typography>
                        <Button onClick={() => setScheduleStep('regular')} variant="contained" style={{backgroundColor: '#222', color: '#f5f5f5'}}>Get Started</Button>
                    </Grid>}

                    {/* 2) CREATE REGULAR SCHEDULE */}
                    {scheduleStep === 'regular' && <Grid item xs={12} style={{padding: '1rem'}}>
                        <Typography style={{fontFamily: 'helvetica neue', fontSize: '2rem', fontWeight: '200', marginBottom: '0.5rem'}}>Set Regular Hours</Typography>
                        
                        {/* SUNDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1" style={{display: 'inline', marginRight: '3rem'}}>Sunday</Typography>
                                {sundayScheduleEditable && <Checkbox
                                    color="default"
                                    checked={sundaySchedule.isClosed}
                                    onChange={(event) => setSundaySchedule({...sundaySchedule, isClosed: event.target.checked})}
                                    style={{padding: 0}}
                                    inputProps={{ 'aria-label': 'sunday closed checkbox' }}
                                />}
                                {sundayScheduleEditable && <Typography variant="caption">Closed?</Typography>}
                                {!sundayScheduleEditable && sundaySchedule.isClosed && <Typography style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>Closed</Typography>}
                                {!sundayScheduleEditable && !sundaySchedule.isClosed && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${sundaySchedule.open.hour > 12 ? sundaySchedule.open.hour - 12 : sundaySchedule.open.hour}:${sundaySchedule.open.minute === 0 ? '00' : sundaySchedule.open.minute}${sundaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${sundaySchedule.close.hour > 12 ? sundaySchedule.close.hour - 12 : sundaySchedule.close.hour}:${sundaySchedule.close.minute === 0 ? '00' : sundaySchedule.close.minute}${sundaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setSundayScheduleEditable(!sundayScheduleEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {sundayScheduleEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {sundayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">OPEN: {sundaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${sundaySchedule.open.hour > 12 ? sundaySchedule.open.hour - 12 : sundaySchedule.open.hour}:${sundaySchedule.open.minute === 0 ? '00' : sundaySchedule.open.minute}${sundaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || sundaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={sundaySchedule.open.hour}
                                        onChange={(event) => { setSundaySchedule({
                                            open: {...sundaySchedule.open, hour: parseInt(event.target.value)},
                                            close: {...sundaySchedule.close},
                                            isClosed: sundaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || sundaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={sundaySchedule.open.minute}
                                        onChange={(event) => { setSundaySchedule({
                                            open: {...sundaySchedule.open, minute: parseInt(event.target.value)},
                                            close: {...sundaySchedule.close},
                                            isClosed: sundaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {sundayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">CLOSE: {sundaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${sundaySchedule.close.hour > 12 ? sundaySchedule.close.hour - 12 : sundaySchedule.close.hour}:${sundaySchedule.close.minute === 0 ? '00' : sundaySchedule.close.minute}${sundaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || sundaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={sundaySchedule.close.hour}
                                        onChange={(event) => { setSundaySchedule({
                                            close: {...sundaySchedule.close, hour: parseInt(event.target.value)},
                                            open: {...sundaySchedule.open},
                                            isClosed: sundaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || sundaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={sundaySchedule.close.minute}
                                        onChange={(event) => { setSundaySchedule({
                                            close: {...sundaySchedule.close, minute: parseInt(event.target.value)},
                                            open: {...sundaySchedule.open},
                                            isClosed: sundaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        {/* MONDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1" style={{display: 'inline', marginRight: '3rem'}}>Monday</Typography>
                                {mondayScheduleEditable && <Checkbox
                                    color="default"
                                    checked={mondaySchedule.isClosed}
                                    onChange={(event) => setMondaySchedule({...mondaySchedule, isClosed: event.target.checked})}
                                    style={{padding: 0}}
                                    inputProps={{ 'aria-label': 'monday closed checkbox' }}
                                />}
                                {mondayScheduleEditable && <Typography variant="caption">Closed?</Typography>}
                                {!mondayScheduleEditable && mondaySchedule.isClosed && <Typography style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>Closed</Typography>}
                                {!mondayScheduleEditable && !mondaySchedule.isClosed && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${mondaySchedule.open.hour > 12 ? mondaySchedule.open.hour - 12 : mondaySchedule.open.hour}:${mondaySchedule.open.minute === 0 ? '00' : mondaySchedule.open.minute}${mondaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${mondaySchedule.close.hour > 12 ? mondaySchedule.close.hour - 12 : mondaySchedule.close.hour}:${mondaySchedule.close.minute === 0 ? '00' : mondaySchedule.close.minute}${mondaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setMondayScheduleEditable(!mondayScheduleEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {mondayScheduleEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {mondayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">OPEN: {mondaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${mondaySchedule.open.hour > 12 ? mondaySchedule.open.hour - 12 : mondaySchedule.open.hour}:${mondaySchedule.open.minute === 0 ? '00' : mondaySchedule.open.minute}${mondaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || mondaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={mondaySchedule.open.hour}
                                        onChange={(event) => { setMondaySchedule({
                                            open: {...mondaySchedule.open, hour: parseInt(event.target.value)},
                                            close: {...mondaySchedule.close},
                                            isClosed: mondaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || mondaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={mondaySchedule.open.minute}
                                        onChange={(event) => { setMondaySchedule({
                                            open: {...mondaySchedule.open, minute: parseInt(event.target.value)},
                                            close: {...mondaySchedule.close},
                                            isClosed: mondaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {mondayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">CLOSE: {mondaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${mondaySchedule.close.hour > 12 ? mondaySchedule.close.hour - 12 : mondaySchedule.close.hour}:${mondaySchedule.close.minute === 0 ? '00' : mondaySchedule.close.minute}${mondaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || mondaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={mondaySchedule.close.hour}
                                        onChange={(event) => { setMondaySchedule({
                                            close: {...mondaySchedule.close, hour: parseInt(event.target.value)},
                                            open: {...mondaySchedule.open},
                                            isClosed: mondaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || mondaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={mondaySchedule.close.minute}
                                        onChange={(event) => { setMondaySchedule({
                                            close: {...mondaySchedule.close, minute: parseInt(event.target.value)},
                                            open: {...mondaySchedule.open},
                                            isClosed: mondaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        {/* TUESDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1" style={{display: 'inline', marginRight: '3rem'}}>Tuesday</Typography>
                                {tuesdayScheduleEditable && <Checkbox
                                    color="default"
                                    checked={tuesdaySchedule.isClosed}
                                    onChange={(event) => setTuesdaySchedule({...sundaySchedule, isClosed: event.target.checked})}
                                    style={{padding: 0}}
                                    inputProps={{ 'aria-label': 'tuesday closed checkbox' }}
                                />}
                                {tuesdayScheduleEditable && <Typography variant="caption">Closed?</Typography>}
                                {!tuesdayScheduleEditable && tuesdaySchedule.isClosed && <Typography style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>Closed</Typography>}
                                {!tuesdayScheduleEditable && !tuesdaySchedule.isClosed && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${tuesdaySchedule.open.hour > 12 ? tuesdaySchedule.open.hour - 12 : tuesdaySchedule.open.hour}:${tuesdaySchedule.open.minute === 0 ? '00' : tuesdaySchedule.open.minute}${tuesdaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${tuesdaySchedule.close.hour > 12 ? tuesdaySchedule.close.hour - 12 : tuesdaySchedule.close.hour}:${tuesdaySchedule.close.minute === 0 ? '00' : tuesdaySchedule.close.minute}${tuesdaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setTuesdayScheduleEditable(!tuesdayScheduleEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {tuesdayScheduleEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {tuesdayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">OPEN: {tuesdaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${tuesdaySchedule.open.hour > 12 ? tuesdaySchedule.open.hour - 12 : tuesdaySchedule.open.hour}:${tuesdaySchedule.open.minute === 0 ? '00' : tuesdaySchedule.open.minute}${tuesdaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || tuesdaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={tuesdaySchedule.open.hour}
                                        onChange={(event) => { setTuesdaySchedule({
                                            open: {...tuesdaySchedule.open, hour: parseInt(event.target.value)},
                                            close: {...tuesdaySchedule.close},
                                            isClosed: tuesdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || tuesdaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={tuesdaySchedule.open.minute}
                                        onChange={(event) => { setTuesdaySchedule({
                                            open: {...tuesdaySchedule.open, minute: parseInt(event.target.value)},
                                            close: {...tuesdaySchedule.close},
                                            isClosed: tuesdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {tuesdayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">CLOSE: {tuesdaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${tuesdaySchedule.close.hour > 12 ? tuesdaySchedule.close.hour - 12 : tuesdaySchedule.close.hour}:${tuesdaySchedule.close.minute === 0 ? '00' : tuesdaySchedule.close.minute}${tuesdaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || tuesdaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={tuesdaySchedule.close.hour}
                                        onChange={(event) => { setTuesdaySchedule({
                                            close: {...tuesdaySchedule.close, hour: parseInt(event.target.value)},
                                            open: {...tuesdaySchedule.open},
                                            isClosed: tuesdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || tuesdaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={tuesdaySchedule.close.minute}
                                        onChange={(event) => { setTuesdaySchedule({
                                            close: {...tuesdaySchedule.close, minute: parseInt(event.target.value)},
                                            open: {...tuesdaySchedule.open},
                                            isClosed: tuesdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        {/* WEDNESDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1" style={{display: 'inline', marginRight: '3rem'}}>Wednesday</Typography>
                                {wednesdayScheduleEditable && <Checkbox
                                    color="default"
                                    checked={wednesdaySchedule.isClosed}
                                    onChange={(event) => setWednesdaySchedule({...wednesdaySchedule, isClosed: event.target.checked})}
                                    style={{padding: 0}}
                                    inputProps={{ 'aria-label': 'wednesday closed checkbox' }}
                                />}
                                {wednesdayScheduleEditable && <Typography variant="caption">Closed?</Typography>}
                                {!wednesdayScheduleEditable && wednesdaySchedule.isClosed && <Typography style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>Closed</Typography>}
                                {!wednesdayScheduleEditable && !wednesdaySchedule.isClosed && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${wednesdaySchedule.open.hour > 12 ? wednesdaySchedule.open.hour - 12 : wednesdaySchedule.open.hour}:${wednesdaySchedule.open.minute === 0 ? '00' : wednesdaySchedule.open.minute}${wednesdaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${wednesdaySchedule.close.hour > 12 ? wednesdaySchedule.close.hour - 12 : wednesdaySchedule.close.hour}:${wednesdaySchedule.close.minute === 0 ? '00' : wednesdaySchedule.close.minute}${wednesdaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setWednesdayScheduleEditable(!wednesdayScheduleEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {wednesdayScheduleEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {wednesdayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">OPEN: {wednesdaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${wednesdaySchedule.open.hour > 12 ? wednesdaySchedule.open.hour - 12 : wednesdaySchedule.open.hour}:${wednesdaySchedule.open.minute === 0 ? '00' : wednesdaySchedule.open.minute}${wednesdaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || wednesdaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={wednesdaySchedule.open.hour}
                                        onChange={(event) => { setWednesdaySchedule({
                                            open: {...wednesdaySchedule.open, hour: parseInt(event.target.value)},
                                            close: {...wednesdaySchedule.close},
                                            isClosed: wednesdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || wednesdaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={wednesdaySchedule.open.minute}
                                        onChange={(event) => { setWednesdaySchedule({
                                            open: {...wednesdaySchedule.open, minute: parseInt(event.target.value)},
                                            close: {...wednesdaySchedule.close},
                                            isClosed: wednesdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {wednesdayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">CLOSE: {wednesdaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${wednesdaySchedule.close.hour > 12 ? wednesdaySchedule.close.hour - 12 : wednesdaySchedule.close.hour}:${wednesdaySchedule.close.minute === 0 ? '00' : wednesdaySchedule.close.minute}${wednesdaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || wednesdaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={wednesdaySchedule.close.hour}
                                        onChange={(event) => { setWednesdaySchedule({
                                            close: {...wednesdaySchedule.close, hour: parseInt(event.target.value)},
                                            open: {...wednesdaySchedule.open},
                                            isClosed: wednesdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || wednesdaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={wednesdaySchedule.close.minute}
                                        onChange={(event) => { setWednesdaySchedule({
                                            close: {...wednesdaySchedule.close, minute: parseInt(event.target.value)},
                                            open: {...wednesdaySchedule.open},
                                            isClosed: wednesdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        {/* THURSDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1" style={{display: 'inline', marginRight: '3rem'}}>Thursday</Typography>
                                {thursdayScheduleEditable && <Checkbox
                                    color="default"
                                    checked={thursdaySchedule.isClosed}
                                    onChange={(event) => setThursdaySchedule({...thursdaySchedule, isClosed: event.target.checked})}
                                    style={{padding: 0}}
                                    inputProps={{ 'aria-label': 'thursday closed checkbox' }}
                                />}
                                {thursdayScheduleEditable && <Typography variant="caption">Closed?</Typography>}
                                {!thursdayScheduleEditable && thursdaySchedule.isClosed && <Typography style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>Closed</Typography>}
                                {!thursdayScheduleEditable && !thursdaySchedule.isClosed && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${thursdaySchedule.open.hour > 12 ? thursdaySchedule.open.hour - 12 : thursdaySchedule.open.hour}:${thursdaySchedule.open.minute === 0 ? '00' : thursdaySchedule.open.minute}${fridaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${thursdaySchedule.close.hour > 12 ? thursdaySchedule.close.hour - 12 : thursdaySchedule.close.hour}:${thursdaySchedule.close.minute === 0 ? '00' : thursdaySchedule.close.minute}${thursdaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setThursdayScheduleEditable(!thursdayScheduleEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {thursdayScheduleEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {thursdayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">OPEN: {thursdaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${thursdaySchedule.open.hour > 12 ? thursdaySchedule.open.hour - 12 : thursdaySchedule.open.hour}:${thursdaySchedule.open.minute === 0 ? '00' : thursdaySchedule.open.minute}${thursdaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || thursdaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={thursdaySchedule.open.hour}
                                        onChange={(event) => { setThursdaySchedule({
                                            open: {...thursdaySchedule.open, hour: parseInt(event.target.value)},
                                            close: {...thursdaySchedule.close},
                                            isClosed: thursdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || thursdaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={thursdaySchedule.open.minute}
                                        onChange={(event) => { setThursdaySchedule({
                                            open: {...thursdaySchedule.open, minute: parseInt(event.target.value)},
                                            close: {...thursdaySchedule.close},
                                            isClosed: thursdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {thursdayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">CLOSE: {thursdaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${thursdaySchedule.close.hour > 12 ? thursdaySchedule.close.hour - 12 : thursdaySchedule.close.hour}:${thursdaySchedule.close.minute === 0 ? '00' : thursdaySchedule.close.minute}${thursdaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || thursdaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={thursdaySchedule.close.hour}
                                        onChange={(event) => { setThursdaySchedule({
                                            close: {...thursdaySchedule.close, hour: parseInt(event.target.value)},
                                            open: {...thursdaySchedule.open},
                                            isClosed: thursdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || thursdaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={thursdaySchedule.close.minute}
                                        onChange={(event) => { setThursdaySchedule({
                                            close: {...thursdaySchedule.close, minute: parseInt(event.target.value)},
                                            open: {...thursdaySchedule.open},
                                            isClosed: thursdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        {/* FRIDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1" style={{display: 'inline', marginRight: '3rem'}}>Friday</Typography>
                                {fridayScheduleEditable && <Checkbox
                                    color="default"
                                    checked={fridaySchedule.isClosed}
                                    onChange={(event) => setFridaySchedule({...fridaySchedule, isClosed: event.target.checked})}
                                    style={{padding: 0}}
                                    inputProps={{ 'aria-label': 'friday closed checkbox' }}
                                />}
                                {fridayScheduleEditable && <Typography variant="caption">Closed?</Typography>}
                                {!fridayScheduleEditable && fridaySchedule.isClosed && <Typography style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>Closed</Typography>}
                                {!fridayScheduleEditable && !fridaySchedule.isClosed && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${fridaySchedule.open.hour > 12 ? fridaySchedule.open.hour - 12 : fridaySchedule.open.hour}:${fridaySchedule.open.minute === 0 ? '00' : fridaySchedule.open.minute}${fridaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${fridaySchedule.close.hour > 12 ? fridaySchedule.close.hour - 12 : fridaySchedule.close.hour}:${fridaySchedule.close.minute === 0 ? '00' : fridaySchedule.close.minute}${fridaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setFridayScheduleEditable(!fridayScheduleEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {fridayScheduleEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {fridayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">OPEN: {fridaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${fridaySchedule.open.hour > 12 ? fridaySchedule.open.hour - 12 : fridaySchedule.open.hour}:${fridaySchedule.open.minute === 0 ? '00' : fridaySchedule.open.minute}${fridaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || fridaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={fridaySchedule.open.hour}
                                        onChange={(event) => { setFridaySchedule({
                                            open: {...fridaySchedule.open, hour: parseInt(event.target.value)},
                                            close: {...fridaySchedule.close},
                                            isClosed: fridaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || fridaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={fridaySchedule.open.minute}
                                        onChange={(event) => { setFridaySchedule({
                                            open: {...fridaySchedule.open, minute: parseInt(event.target.value)},
                                            close: {...fridaySchedule.close},
                                            isClosed: fridaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {fridayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">CLOSE: {fridaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${fridaySchedule.close.hour > 12 ? fridaySchedule.close.hour - 12 : fridaySchedule.close.hour}:${fridaySchedule.close.minute === 0 ? '00' : fridaySchedule.close.minute}${fridaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || fridaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={fridaySchedule.close.hour}
                                        onChange={(event) => { setFridaySchedule({
                                            close: {...fridaySchedule.close, hour: parseInt(event.target.value)},
                                            open: {...fridaySchedule.open},
                                            isClosed: fridaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || fridaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={fridaySchedule.close.minute}
                                        onChange={(event) => { setFridaySchedule({
                                            close: {...fridaySchedule.close, minute: parseInt(event.target.value)},
                                            open: {...fridaySchedule.open},
                                            isClosed: fridaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        {/* SATURDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1" style={{display: 'inline', marginRight: '3rem'}}>Saturday</Typography>
                                {saturdayScheduleEditable && <Checkbox
                                    color="default"
                                    checked={saturdaySchedule.isClosed}
                                    onChange={(event) => setSaturdaySchedule({...saturdaySchedule, isClosed: event.target.checked})}
                                    style={{padding: 0}}
                                    inputProps={{ 'aria-label': 'sunday closed checkbox' }}
                                />}
                                {saturdayScheduleEditable && <Typography variant="caption">Closed?</Typography>}
                                {!saturdayScheduleEditable && saturdaySchedule.isClosed && <Typography style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>Closed</Typography>}
                                {!saturdayScheduleEditable && !saturdaySchedule.isClosed && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${saturdaySchedule.open.hour > 12 ? saturdaySchedule.open.hour - 12 : saturdaySchedule.open.hour}:${saturdaySchedule.open.minute === 0 ? '00' : saturdaySchedule.open.minute}${saturdaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${saturdaySchedule.close.hour > 12 ? saturdaySchedule.close.hour - 12 : saturdaySchedule.close.hour}:${saturdaySchedule.close.minute === 0 ? '00' : saturdaySchedule.close.minute}${saturdaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setSaturdayScheduleEditable(!saturdayScheduleEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {saturdayScheduleEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {saturdayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">OPEN: {saturdaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${saturdaySchedule.open.hour > 12 ? saturdaySchedule.open.hour - 12 : saturdaySchedule.open.hour}:${saturdaySchedule.open.minute === 0 ? '00' : saturdaySchedule.open.minute}${saturdaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || saturdaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={saturdaySchedule.open.hour}
                                        onChange={(event) => { setSaturdaySchedule({
                                            open: {...saturdaySchedule.open, hour: parseInt(event.target.value)},
                                            close: {...saturdaySchedule.close},
                                            isClosed: saturdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || saturdaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={saturdaySchedule.open.minute}
                                        onChange={(event) => { setSaturdaySchedule({
                                            open: {...saturdaySchedule.open, minute: parseInt(event.target.value)},
                                            close: {...saturdaySchedule.close},
                                            isClosed: saturdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {saturdayScheduleEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">CLOSE: {saturdaySchedule.isClosed ? '-' : <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${saturdaySchedule.close.hour > 12 ? saturdaySchedule.close.hour - 12 : saturdaySchedule.close.hour}:${saturdaySchedule.close.minute === 0 ? '00' : saturdaySchedule.close.minute}${saturdaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span>}</Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || saturdaySchedule.isClosed}
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={saturdaySchedule.close.hour}
                                        onChange={(event) => { setSaturdaySchedule({
                                            close: {...saturdaySchedule.close, hour: parseInt(event.target.value)},
                                            open: {...saturdaySchedule.open},
                                            isClosed: saturdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || saturdaySchedule.isClosed}
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={saturdaySchedule.close.minute}
                                        onChange={(event) => { setSaturdaySchedule({
                                            close: {...saturdaySchedule.close, minute: parseInt(event.target.value)},
                                            open: {...saturdaySchedule.open},
                                            isClosed: saturdaySchedule.isClosed
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        <Button onClick={() => setRegularScheduleConfirmationVisible(true)} variant="contained" style={{backgroundColor: '#222', color: '#f5f5f5', textTransform: 'none'}}>Submit Hours</Button>
                    </Grid>}

                    {/* 3) CREATE BREAK SCHEDULE */}
                    {scheduleStep === 'breaks' && <Grid item xs={12} style={{padding: '1rem'}}>
                        <Typography style={{fontFamily: 'helvetica neue', fontSize: '2rem', fontWeight: '200', marginBottom: '0.5rem'}}>Set Regular Breaks</Typography>
                        
                        {/* SUNDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1">Sunday</Typography>
                                {!sundayBreakEditable && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${sundayBreak.start.hour > 12 ? sundayBreak.start.hour - 12 : sundayBreak.start.hour}:${sundayBreak.start.minute === 0 ? '00' : sundayBreak.start.minute}${sundayBreak.start.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${sundayBreak.end.hour > 12 ? sundayBreak.end.hour - 12 : sundayBreak.end.hour}:${sundayBreak.end.minute === 0 ? '00' : sundayBreak.end.minute}${sundayBreak.end.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setSundayBreakEditable(!sundayBreakEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {sundayBreakEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {sundayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">START: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${sundayBreak.start.hour > 12 ? sundayBreak.start.hour - 12 : sundayBreak.start.hour}:${sundayBreak.start.minute === 0 ? '00' : sundayBreak.start.minute}${sundayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={sundayBreak.start.hour}
                                        onChange={(event) => { setSundayBreak({
                                            start: {...sundayBreak.start, hour: parseInt(event.target.value)},
                                            end: {...sundayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={sundayBreak.start.minute}
                                        onChange={(event) => { setSundayBreak({
                                            start: {...sundayBreak.start, minute: parseInt(event.target.value)},
                                            end: {...sundayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {sundayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">END: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${sundayBreak.end.hour > 12 ? sundayBreak.end.hour - 12 : sundayBreak.end.hour}:${sundayBreak.end.minute === 0 ? '00' : sundayBreak.end.minute}${sundayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={sundayBreak.end.hour}
                                        onChange={(event) => { setSundayBreak({
                                            end: {...sundayBreak.end, hour: parseInt(event.target.value)},
                                            start: {...sundayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={sundayBreak.end.minute}
                                        onChange={(event) => { setSundayBreak({
                                            end: {...sundayBreak.end, minute: parseInt(event.target.value)},
                                            start: {...sundayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        {/* MONDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1">Monday</Typography>
                                {!mondayBreakEditable && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${mondayBreak.start.hour > 12 ? mondayBreak.start.hour - 12 : mondayBreak.start.hour}:${mondayBreak.start.minute === 0 ? '00' : mondayBreak.start.minute}${mondayBreak.start.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${mondayBreak.end.hour > 12 ? mondayBreak.end.hour - 12 : mondayBreak.end.hour}:${mondayBreak.end.minute === 0 ? '00' : mondayBreak.end.minute}${mondayBreak.end.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setMondayBreakEditable(!mondayBreakEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {mondayBreakEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {mondayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">START: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${mondayBreak.start.hour > 12 ? mondayBreak.start.hour - 12 : mondayBreak.start.hour}:${mondayBreak.start.minute === 0 ? '00' : mondayBreak.start.minute}${mondayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={mondayBreak.start.hour}
                                        onChange={(event) => { setMondayBreak({
                                            start: {...mondayBreak.start, hour: parseInt(event.target.value)},
                                            end: {...mondayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={mondayBreak.start.minute}
                                        onChange={(event) => { setMondayBreak({
                                            start: {...mondayBreak.start, minute: parseInt(event.target.value)},
                                            end: {...mondayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {mondayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">END: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${mondayBreak.end.hour > 12 ? mondayBreak.end.hour - 12 : mondayBreak.end.hour}:${mondayBreak.end.minute === 0 ? '00' : mondayBreak.end.minute}${mondayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={mondayBreak.end.hour}
                                        onChange={(event) => { setMondayBreak({
                                            end: {...mondayBreak.end, hour: parseInt(event.target.value)},
                                            start: {...mondayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={mondayBreak.end.minute}
                                        onChange={(event) => { setMondayBreak({
                                            end: {...mondayBreak.end, minute: parseInt(event.target.value)},
                                            start: {...mondayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        {/* TUESDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1">Tuesday</Typography>
                                {!tuesdayBreakEditable && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${tuesdayBreak.start.hour > 12 ? tuesdayBreak.start.hour - 12 : tuesdayBreak.start.hour}:${tuesdayBreak.start.minute === 0 ? '00' : tuesdayBreak.start.minute}${tuesdayBreak.start.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${tuesdayBreak.end.hour > 12 ? tuesdayBreak.end.hour - 12 : tuesdayBreak.end.hour}:${tuesdayBreak.end.minute === 0 ? '00' : tuesdayBreak.end.minute}${tuesdayBreak.end.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setTuesdayBreakEditable(!tuesdayBreakEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {tuesdayBreakEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {tuesdayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">START: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${tuesdayBreak.start.hour > 12 ? tuesdayBreak.start.hour - 12 : tuesdayBreak.start.hour}:${tuesdayBreak.start.minute === 0 ? '00' : tuesdayBreak.start.minute}${tuesdayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={tuesdayBreak.start.hour}
                                        onChange={(event) => { setTuesdayBreak({
                                            start: {...tuesdayBreak.start, hour: parseInt(event.target.value)},
                                            end: {...tuesdayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={tuesdayBreak.start.minute}
                                        onChange={(event) => { setTuesdayBreak({
                                            start: {...tuesdayBreak.start, minute: parseInt(event.target.value)},
                                            end: {...tuesdayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {tuesdayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">END: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${tuesdayBreak.end.hour > 12 ? tuesdayBreak.end.hour - 12 : tuesdayBreak.end.hour}:${tuesdayBreak.end.minute === 0 ? '00' : tuesdayBreak.end.minute}${tuesdayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={tuesdayBreak.end.hour}
                                        onChange={(event) => { setTuesdayBreak({
                                            end: {...tuesdayBreak.end, hour: parseInt(event.target.value)},
                                            start: {...tuesdayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={tuesdayBreak.end.minute}
                                        onChange={(event) => { setTuesdayBreak({
                                            end: {...tuesdayBreak.end, minute: parseInt(event.target.value)},
                                            start: {...tuesdayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        {/* WEDNESDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1">Wednesday</Typography>
                                {!wednesdayBreakEditable && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${wednesdayBreak.start.hour > 12 ? wednesdayBreak.start.hour - 12 : wednesdayBreak.start.hour}:${wednesdayBreak.start.minute === 0 ? '00' : wednesdayBreak.start.minute}${wednesdayBreak.start.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${wednesdayBreak.end.hour > 12 ? wednesdayBreak.end.hour - 12 : wednesdayBreak.end.hour}:${wednesdayBreak.end.minute === 0 ? '00' : wednesdayBreak.end.minute}${wednesdayBreak.end.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setWednesdayBreakEditable(!wednesdayBreakEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {wednesdayBreakEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {wednesdayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">START: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${wednesdayBreak.start.hour > 12 ? wednesdayBreak.start.hour - 12 : wednesdayBreak.start.hour}:${wednesdayBreak.start.minute === 0 ? '00' : wednesdayBreak.start.minute}${wednesdayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={wednesdayBreak.start.hour}
                                        onChange={(event) => { setWednesdayBreak({
                                            start: {...wednesdayBreak.start, hour: parseInt(event.target.value)},
                                            end: {...wednesdayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={wednesdayBreak.start.minute}
                                        onChange={(event) => { setWednesdayBreak({
                                            start: {...wednesdayBreak.start, minute: parseInt(event.target.value)},
                                            end: {...wednesdayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {wednesdayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">END: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${wednesdayBreak.end.hour > 12 ? wednesdayBreak.end.hour - 12 : wednesdayBreak.end.hour}:${wednesdayBreak.end.minute === 0 ? '00' : wednesdayBreak.end.minute}${wednesdayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={wednesdayBreak.end.hour}
                                        onChange={(event) => { setWednesdayBreak({
                                            end: {...wednesdayBreak.end, hour: parseInt(event.target.value)},
                                            start: {...wednesdayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={wednesdayBreak.end.minute}
                                        onChange={(event) => { setWednesdayBreak({
                                            end: {...wednesdayBreak.end, minute: parseInt(event.target.value)},
                                            start: {...wednesdayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        {/* THURSDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1">Thursday</Typography>
                                {!thursdayBreakEditable && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${thursdayBreak.start.hour > 12 ? thursdayBreak.start.hour - 12 : thursdayBreak.start.hour}:${thursdayBreak.start.minute === 0 ? '00' : thursdayBreak.start.minute}${thursdayBreak.start.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${thursdayBreak.end.hour > 12 ? thursdayBreak.end.hour - 12 : thursdayBreak.end.hour}:${thursdayBreak.end.minute === 0 ? '00' : thursdayBreak.end.minute}${thursdayBreak.end.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setThursdayBreakEditable(!thursdayBreakEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {thursdayBreakEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {thursdayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">START: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${thursdayBreak.start.hour > 12 ? thursdayBreak.start.hour - 12 : thursdayBreak.start.hour}:${thursdayBreak.start.minute === 0 ? '00' : thursdayBreak.start.minute}${thursdayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={thursdayBreak.start.hour}
                                        onChange={(event) => { setThursdayBreak({
                                            start: {...thursdayBreak.start, hour: parseInt(event.target.value)},
                                            end: {...thursdayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={thursdayBreak.start.minute}
                                        onChange={(event) => { setThursdayBreak({
                                            start: {...thursdayBreak.start, minute: parseInt(event.target.value)},
                                            end: {...thursdayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {thursdayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">END: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${thursdayBreak.end.hour > 12 ? thursdayBreak.end.hour - 12 : thursdayBreak.end.hour}:${thursdayBreak.end.minute === 0 ? '00' : thursdayBreak.end.minute}${thursdayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={thursdayBreak.end.hour}
                                        onChange={(event) => { setThursdayBreak({
                                            end: {...thursdayBreak.end, hour: parseInt(event.target.value)},
                                            start: {...thursdayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={thursdayBreak.end.minute}
                                        onChange={(event) => { setThursdayBreak({
                                            end: {...thursdayBreak.end, minute: parseInt(event.target.value)},
                                            start: {...thursdayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        {/* FRIDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1">Friday</Typography>
                                {!fridayBreakEditable && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${fridayBreak.start.hour > 12 ? fridayBreak.start.hour - 12 : fridayBreak.start.hour}:${fridayBreak.start.minute === 0 ? '00' : fridayBreak.start.minute}${fridayBreak.start.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${fridayBreak.end.hour > 12 ? fridayBreak.end.hour - 12 : fridayBreak.end.hour}:${fridayBreak.end.minute === 0 ? '00' : fridayBreak.end.minute}${fridayBreak.end.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setFridayBreakEditable(!fridayBreakEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {fridayBreakEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {fridayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">START: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${fridayBreak.start.hour > 12 ? fridayBreak.start.hour - 12 : fridayBreak.start.hour}:${fridayBreak.start.minute === 0 ? '00' : fridayBreak.start.minute}${fridayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={fridayBreak.start.hour}
                                        onChange={(event) => { setFridayBreak({
                                            start: {...fridayBreak.start, hour: parseInt(event.target.value)},
                                            end: {...fridayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={fridayBreak.start.minute}
                                        onChange={(event) => { setFridayBreak({
                                            start: {...fridayBreak.start, minute: parseInt(event.target.value)},
                                            end: {...fridayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {fridayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">END: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${fridayBreak.end.hour > 12 ? fridayBreak.end.hour - 12 : fridayBreak.end.hour}:${fridayBreak.end.minute === 0 ? '00' : fridayBreak.end.minute}${fridayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={fridayBreak.end.hour}
                                        onChange={(event) => { setFridayBreak({
                                            end: {...fridayBreak.end, hour: parseInt(event.target.value)},
                                            start: {...fridayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={fridayBreak.end.minute}
                                        onChange={(event) => { setFridayBreak({
                                            end: {...fridayBreak.end, minute: parseInt(event.target.value)},
                                            start: {...fridayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        {/* SATURDAY */}
                        <Grid item container xs={12} style={{borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Typography variant="body1">Saturday</Typography>
                                {!saturdayBreakEditable && (
                                    <Typography>
                                        <span style={{position: 'absolute', top: '1.5rem', fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                            {`${saturdayBreak.start.hour > 12 ? saturdayBreak.start.hour - 12 : saturdayBreak.start.hour}:${saturdayBreak.start.minute === 0 ? '00' : saturdayBreak.start.minute}${saturdayBreak.start.hour > 11 ? 'PM' : 'AM'}`}&nbsp;
                                            -&nbsp;{`${saturdayBreak.end.hour > 12 ? saturdayBreak.end.hour - 12 : saturdayBreak.end.hour}:${saturdayBreak.end.minute === 0 ? '00' : saturdayBreak.end.minute}${saturdayBreak.end.hour > 11 ? 'PM' : 'AM'}`}
                                        </span>
                                    </Typography>
                                )}
                                <div style={{flexGrow: 1}}/>
                                <IconButton onClick={() => setSaturdayBreakEditable(!saturdayBreakEditable)} aria-label="expand" style={{position: 'absolute', top: 0, right: 0}}>
                                    {saturdayBreakEditable ? <UpIcon style={{color: '#222'}}/> : <DownIcon style={{color: '#222'}}/>}
                                </IconButton>
                            </Grid>
                            {saturdayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">START: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${saturdayBreak.start.hour > 12 ? saturdayBreak.start.hour - 12 : saturdayBreak.start.hour}:${saturdayBreak.start.minute === 0 ? '00' : saturdayBreak.start.minute}${saturdayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={saturdayBreak.start.hour}
                                        onChange={(event) => { setSaturdayBreak({
                                            start: {...saturdayBreak.start, hour: parseInt(event.target.value)},
                                            end: {...saturdayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={saturdayBreak.start.minute}
                                        onChange={(event) => { setSaturdayBreak({
                                            start: {...saturdayBreak.start, minute: parseInt(event.target.value)},
                                            end: {...saturdayBreak.end}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                            {saturdayBreakEditable && <Grid item container xs={12} md={6}>
                                <Grid item xs={12} style={{paddingTop: '1rem'}}><Typography variant="caption">END: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${saturdayBreak.end.hour > 12 ? saturdayBreak.end.hour - 12 : saturdayBreak.end.hour}:${saturdayBreak.end.minute === 0 ? '00' : saturdayBreak.end.minute}${saturdayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></Typography></Grid>
                                <Grid item xs={6} style={{padding: '0.5rem', paddingLeft: 0}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Hour"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={saturdayBreak.end.hour}
                                        onChange={(event) => { setSaturdayBreak({
                                            end: {...saturdayBreak.end, hour: parseInt(event.target.value)},
                                            start: {...saturdayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((hour) => { return (<MenuItem key={hour} value={hour}>{hour > 12 ? `${hour - 12}` : hour}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0.5rem'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Minute"
                                        variant="outlined"
                                        style={{marginTop: '1rem'}}
                                        value={saturdayBreak.end.minute}
                                        onChange={(event) => { setSaturdayBreak({
                                            end: {...saturdayBreak.end, minute: parseInt(event.target.value)},
                                            start: {...saturdayBreak.start}
                                        })}}
                                        className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                                    >
                                        {[0, 15, 30, 45].map((minute) => { return (<MenuItem key={minute} value={minute}>{minute === 0 ? '00' : minute}</MenuItem>)})}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </Grid>

                        <Button onClick={() => setScheduleStep('regular')} variant="contained" style={{backgroundColor: '#ccc', color: '#444', textTransform: 'none', marginRight: '1rem'}}>Edit Regular Schedule</Button>
                        <Button onClick={() => setBreakScheduleConfirmationVisible(true)} variant="contained" style={{backgroundColor: '#222', color: '#f5f5f5', textTransform: 'none'}}>Set Break Schedule</Button>
                    </Grid>}

                    {/* 4) CREATE HOLIDAY BREAKS */}
                    {scheduleStep === 'holidays' && <Grid item xs={12} style={{padding: '1rem'}}>
                        <div style={{display: 'flex'}}>
                            <Typography style={{fontFamily: 'helvetica neue', fontSize: '2rem', fontWeight: '200', marginBottom: '0.5rem', lineHeight: 1}}>Add Holidays</Typography>
                            <div style={{flexGrow: 1}}/>
                            <IconButton onClick={() => setCreateHolidayModalVisible(true)} aria-label="add">
                                <AddIcon style={{color: '#222'}}/>
                            </IconButton>
                        </div>
                        
                        <Grid item container xs={12} style={{marginBottom: '1rem', paddingBottom: '2rem'}}>
                            <Grid item xs={12} style={{position: 'relative', marginBottom: '1rem'}}>
                                <Typography variant="body1">Current Holidays</Typography>
                            </Grid>

                            {holidays.length < 1 && <Typography style={{fontFamily: 'helvetica neue', fontWeight: '200', fontSize: '1rem'}} variant="caption">You haven't set any holiday time.</Typography>}
                            {holidays.length > 0 && holidays.map((holiday) => { return <Chip key={holiday.name} label={`${holiday.name}`} style={{backgroundColor: '#444', color: '#f5f5f5', marginRight: '1rem', marginBottom: '1rem'}} onDelete={() => {setSelectedHoliday(holiday); setDeleteHolidayConfirmationVisible(true)}} color="primary" />})}
                        </Grid>

                        <Button onClick={() => setScheduleStep('breaks')} variant="contained" style={{backgroundColor: '#ccc', color: '#444', textTransform: 'none', marginRight: '1rem', marginBottom: '1rem'}}>Edit Break Schedule</Button>
                        <Button onClick={setHolidaySchedule} variant="contained" style={{backgroundColor: '#222', color: '#f5f5f5', textTransform: 'none', marginBottom: '1rem'}}>Set Holidays</Button>
                    </Grid>}
                </Grid>
            </FadeIn>

            {/* REGULAR SCHEDULE CONFIRMATION DIALOG */}
            <FadeIn>
                <Dialog open={regularScheduleConfirmationVisible} onClose={() => setRegularScheduleConfirmationVisible(false) } aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: '#444'}}/> : 'Are You Sure?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography style={{fontFamily: 'helvetica neue', fontWeight: '400', marginBottom: '1rem'}}>
                                You are about to set your regular schedule. Does everything look okay? You'll be able to add breaks in the next step.
                            </Typography>
                            <div>Sunday: {sundaySchedule.isClosed ? <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>closed</span> : <span><span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${sundaySchedule.open.hour > 12 ? sundaySchedule.open.hour - 12 : sundaySchedule.open.hour}:${sundaySchedule.open.minute === 0 ? '00' : sundaySchedule.open.minute}${sundaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${sundaySchedule.close.hour > 12 ? sundaySchedule.close.hour - 12 : sundaySchedule.close.hour}:${sundaySchedule.close.minute === 0 ? '00' : sundaySchedule.close.minute}${sundaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span></span>}</div>
                            <div>Monday: {mondaySchedule.isClosed ? <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>closed</span> : <span><span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${mondaySchedule.open.hour > 12 ? mondaySchedule.open.hour - 12 : mondaySchedule.open.hour}:${mondaySchedule.open.minute === 0 ? '00' : mondaySchedule.open.minute}${mondaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${mondaySchedule.close.hour > 12 ? mondaySchedule.close.hour - 12 : mondaySchedule.close.hour}:${mondaySchedule.close.minute === 0 ? '00' : mondaySchedule.close.minute}${mondaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span></span>}</div>
                            <div>Tuesday: {tuesdaySchedule.isClosed ? <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>closed</span> : <span><span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${tuesdaySchedule.open.hour > 12 ? tuesdaySchedule.open.hour - 12 : tuesdaySchedule.open.hour}:${tuesdaySchedule.open.minute === 0 ? '00' : tuesdaySchedule.open.minute}${tuesdaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${tuesdaySchedule.close.hour > 12 ? tuesdaySchedule.close.hour - 12 : tuesdaySchedule.close.hour}:${tuesdaySchedule.close.minute === 0 ? '00' : tuesdaySchedule.close.minute}${tuesdaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span></span>}</div>
                            <div>Wednesday: {wednesdaySchedule.isClosed ? <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>closed</span> : <span><span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${wednesdaySchedule.open.hour > 12 ? wednesdaySchedule.open.hour - 12 : wednesdaySchedule.open.hour}:${wednesdaySchedule.open.minute === 0 ? '00' : wednesdaySchedule.open.minute}${wednesdaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${wednesdaySchedule.close.hour > 12 ? wednesdaySchedule.close.hour - 12 : wednesdaySchedule.close.hour}:${wednesdaySchedule.close.minute === 0 ? '00' : wednesdaySchedule.close.minute}${wednesdaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span></span>}</div>
                            <div>Thursday: {thursdaySchedule.isClosed ? <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>closed</span> : <span><span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${thursdaySchedule.open.hour > 12 ? thursdaySchedule.open.hour - 12 : thursdaySchedule.open.hour}:${thursdaySchedule.open.minute === 0 ? '00' : thursdaySchedule.open.minute}${thursdaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${thursdaySchedule.close.hour > 12 ? thursdaySchedule.close.hour - 12 : thursdaySchedule.close.hour}:${thursdaySchedule.close.minute === 0 ? '00' : thursdaySchedule.close.minute}${thursdaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span></span>}</div>
                            <div>Friday: {fridaySchedule.isClosed ? <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>closed</span> : <span><span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${fridaySchedule.open.hour > 12 ? fridaySchedule.open.hour - 12 : fridaySchedule.open.hour}:${fridaySchedule.open.minute === 0 ? '00' : fridaySchedule.open.minute}${fridaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${fridaySchedule.close.hour > 12 ? fridaySchedule.close.hour - 12 : fridaySchedule.close.hour}:${fridaySchedule.close.minute === 0 ? '00' : fridaySchedule.close.minute}${fridaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span></span>}</div>
                            <div>Saturday: {saturdaySchedule.isClosed ? <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>closed</span> : <span><span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${saturdaySchedule.open.hour > 12 ? saturdaySchedule.open.hour - 12 : saturdaySchedule.open.hour}:${saturdaySchedule.open.minute === 0 ? '00' : saturdaySchedule.open.minute}${saturdaySchedule.open.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${saturdaySchedule.close.hour > 12 ? saturdaySchedule.close.hour - 12 : saturdaySchedule.close.hour}:${saturdaySchedule.close.minute === 0 ? '00' : saturdaySchedule.close.minute}${saturdaySchedule.close.hour > 11 ? 'PM' : 'AM'}`}</span></span>}</div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setRegularScheduleConfirmationVisible(false)} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem'}}>
                            Cancel
                        </Button>
                        <Button disabled={loading} onClick={setRegularSchedule} variant="contained" style={{backgroundColor: '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                            Set Schedule
                        </Button>
                    </DialogActions>
                </Dialog>
            </FadeIn>

            {/* BREAK SCHEDULE CONFIRMATION DIALOG */}
            <FadeIn>
                <Dialog open={breakScheduleConfirmationVisible} onClose={() => setBreakScheduleConfirmationVisible(false) } aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: '#444'}}/> : 'Are You Sure?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography style={{fontFamily: 'helvetica neue', fontWeight: '400', marginBottom: '1rem'}}>
                                You are about to set your break schedule. Does everything look okay? You'll be able to add holidays in the next step.
                            </Typography>
                            <div>Sunday: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${sundayBreak.start.hour > 12 ? sundayBreak.start.hour - 12 : sundayBreak.start.hour}:${sundayBreak.start.minute === 0 ? '00' : sundayBreak.start.minute}${sundayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${sundayBreak.end.hour > 12 ? sundayBreak.end.hour - 12 : sundayBreak.end.hour}:${sundayBreak.end.minute === 0 ? '00' : sundayBreak.end.minute}${sundayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></div>
                            <div>Monday: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${mondayBreak.start.hour > 12 ? mondayBreak.start.hour - 12 : mondayBreak.start.hour}:${mondayBreak.start.minute === 0 ? '00' : mondayBreak.start.minute}${mondayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${mondayBreak.end.hour > 12 ? mondayBreak.end.hour - 12 : mondayBreak.end.hour}:${mondayBreak.end.minute === 0 ? '00' : mondayBreak.end.minute}${mondayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></div>
                            <div>Tuesday: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${tuesdayBreak.start.hour > 12 ? tuesdayBreak.start.hour - 12 : tuesdayBreak.start.hour}:${tuesdayBreak.start.minute === 0 ? '00' : tuesdayBreak.start.minute}${tuesdayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${tuesdayBreak.end.hour > 12 ? tuesdayBreak.end.hour - 12 : tuesdayBreak.end.hour}:${tuesdayBreak.end.minute === 0 ? '00' : tuesdayBreak.end.minute}${tuesdayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></div>
                            <div>Wednesday: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${wednesdayBreak.start.hour > 12 ? wednesdayBreak.start.hour - 12 : wednesdayBreak.start.hour}:${wednesdayBreak.start.minute === 0 ? '00' : wednesdayBreak.start.minute}${wednesdayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${wednesdayBreak.end.hour > 12 ? wednesdayBreak.end.hour - 12 : wednesdayBreak.end.hour}:${wednesdayBreak.end.minute === 0 ? '00' : wednesdayBreak.end.minute}${wednesdayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></div>
                            <div>Thursday: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${thursdayBreak.start.hour > 12 ? thursdayBreak.start.hour - 12 : thursdayBreak.start.hour}:${thursdayBreak.start.minute === 0 ? '00' : thursdayBreak.start.minute}${thursdayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${thursdayBreak.end.hour > 12 ? thursdayBreak.end.hour - 12 : thursdayBreak.end.hour}:${thursdayBreak.end.minute === 0 ? '00' : thursdayBreak.end.minute}${thursdayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></div>
                            <div>Friday: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${fridayBreak.start.hour > 12 ? fridayBreak.start.hour - 12 : fridayBreak.start.hour}:${fridayBreak.start.minute === 0 ? '00' : fridayBreak.start.minute}${fridayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${fridayBreak.end.hour > 12 ? fridayBreak.end.hour - 12 : fridayBreak.end.hour}:${fridayBreak.end.minute === 0 ? '00' : fridayBreak.end.minute}${fridayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></div>
                            <div>Saturday: <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${saturdayBreak.start.hour > 12 ? saturdayBreak.start.hour - 12 : saturdayBreak.start.hour}:${saturdayBreak.start.minute === 0 ? '00' : saturdayBreak.start.minute}${saturdayBreak.start.hour > 11 ? 'PM' : 'AM'}`}</span> - <span style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>{`${saturdayBreak.end.hour > 12 ? saturdayBreak.end.hour - 12 : saturdayBreak.end.hour}:${saturdayBreak.end.minute === 0 ? '00' : saturdayBreak.end.minute}${saturdayBreak.end.hour > 11 ? 'PM' : 'AM'}`}</span></div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setBreakScheduleConfirmationVisible(false)} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem'}}>
                            Cancel
                        </Button>
                        <Button disabled={loading} onClick={setBreakSchedule} variant="contained" style={{backgroundColor: '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                            Set Breaks
                        </Button>
                    </DialogActions>
                </Dialog>
            </FadeIn>

            {/* CREATE HOLIDAY MODAL */}
            <FadeIn>
                <Dialog open={createHolidayModalVisible} onClose={() => setCreateHolidayModalVisible(false) } aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: '#444'}}/> : 'Add Holiday'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography style={{fontFamily: 'helvetica neue', fontWeight: '400', marginBottom: '1rem'}}>
                                You are about to set your regular schedule. Does everything look okay? You'll be able to add breaks in the next step.
                            </Typography>
                        </DialogContentText>
                        <TextField
                            fullWidth
                            label="Name"
                            variant="outlined"
                            style={{marginTop: '1rem'}}
                            value={holidayName}
                            onChange={(event) => setHolidayName(event.target.value)}
                            className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                        />
                        <TextField
                            fullWidth
                            select
                            label="Month"
                            variant="outlined"
                            style={{marginTop: '1rem'}}
                            value={holidayMonth}
                            onChange={(event) => setHolidayMonth(event.target.value)}
                            className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                        >
                            {[{name: 'January', value: 0}, {name: 'February', value: 1}, {name: 'March', value: 2}, {name: 'April', value: 3}, {name: 'May', value: 4}, {name: 'June', value: 5}, {name: 'July', value: 6}, {name: 'August', value: 7}, {name: 'September', value: 8}, {name: 'October', value: 9}, {name: 'November', value: 10}, {name: 'December', value: 11}].map((month) => { return (<MenuItem key={month.value} value={month.value}>{month.name}</MenuItem>)})}
                        </TextField>
                        <TextField
                            fullWidth
                            select
                            label="Date"
                            variant="outlined"
                            style={{marginTop: '1rem'}}
                            value={holidayDate}
                            onChange={(event) => setHolidayDate(event.target.value)}
                            className={`${classes.inputDarkWhiteBackground} ${classes.blockInput}`}
                        >
                            {[...Array(32).keys()].map((date) => { return date === 0 ? null : (<MenuItem key={date} value={date}>{date}</MenuItem>)})}
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setCreateHolidayModalVisible(false)} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem'}}>
                            Cancel
                        </Button>
                        <Button disabled={loading} onClick={addHoliday} variant="contained" style={{backgroundColor: '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                            Add Holiday
                        </Button>
                    </DialogActions>
                </Dialog>
            </FadeIn>

            {/* DELETE HOLIDAY CONFIRMATION */}
            <Dialog open={deleteHolidayConfirmationVisible} onClose={() => setDeleteHolidayConfirmationVisible(false) } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: '#444'}}/> : 'Are You Sure?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography style={{fontFamily: 'helvetica neue', fontWeight: '400', marginBottom: '1rem'}}>
                            You are about to delete <span style={{fontWeight: '700'}}>{selectedHoliday && selectedHoliday.name} ({`${selectedHoliday && selectedHoliday.month+1}/${selectedHoliday && selectedHoliday.date}`})</span> from your holiday schedule.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => setDeleteHolidayConfirmationVisible(false)} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem'}}>
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={deleteHoliday} variant="contained" style={{backgroundColor: '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                        Delete Holiday
                    </Button>
                </DialogActions>
            </Dialog>

            {/* EDIT SCHEDULE OPTIONS DIALOG */}
            <Dialog open={editScheduleDialogVisible} onClose={() => setEditScheduleDialogVisible(false) } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Which would you like to edit?</DialogTitle>
                <DialogContent>
                    <div>
                        <Button fullWidth onClick={() => {setScheduleStep('regular'); setEditScheduleDialogVisible(false)}} variant="contained" style={{backgroundColor: '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                             Regular Schedule
                        </Button>
                    </div>
                    <div>
                        <Button fullWidth onClick={() => {setScheduleStep('breaks'); setEditScheduleDialogVisible(false)}} variant="contained" style={{backgroundColor: '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                             Break Schedule
                        </Button>
                    </div>
                    <div>
                        <Button fullWidth onClick={() => {setScheduleStep('holidays'); setEditScheduleDialogVisible(false)}} variant="contained" style={{backgroundColor: '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                             Holiday Schedule
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default withStyles(GlobalStyle)(Schedule);