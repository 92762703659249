import productsImage from '../assets/images/products.jpg';

const GlobalStyle = theme => ({
    // root: {
        // width: 200,
        // "& .MuiOutlinedInput-input": {
        //   color: "green"
        // },
        // "& .MuiInputLabel-root": {
        //   color: "green"
        // },
        // "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        //   borderColor: "green"
        // },
        // "&:hover .MuiOutlinedInput-input": {
        //   color: "red"
        // },
        // "&:hover .MuiInputLabel-root": {
        //   color: "red"
        // },
        // "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        //   borderColor: "red"
        // },
        // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        //   color: "red"
        // },
        // "& .MuiInputLabel-root.Mui-focused": {
        //   color: "red"
        // },
        // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        //   borderColor: "red"
        // }
    // },
    // ['fieldset.MuiOutlinedInput-notchedOutline']: {
    //     display: 'none !important'
    // },
    // customSelect: {
    //     '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //         borderColor: '#222 !important'
    //     }
    // },
    hide: {
        display: 'none'
    },
    notchedOutline: {
        [`&.Mui-focused`]: {
            borderWidth: '5px',
            borderColor: 'red !important'
        }
    },
    fullBackgroundImage: {
        // backgroundImage: `url(${productsImage})`,
        // '-webkit-background-size': 'cover',
        // '-moz-background-size': 'cover',
        // '-o-background-size': 'cover',
        // backgroundSize: 'cover',
        // backgroundPosition: 'top right'
    },
    tcl: {
        '& .MuiDataGrid-root .MuiDataGrid-cell': {
            fontFamily: 'helvetica neue',
            fontWeight: '400',
        }
    },
    inputDark: {
        backgroundColor: 'transparent',
        color: '#222',
        marginBottom: '1rem',
        '& $notchedOutline': {
            borderColor: '#ccc !important',
        },
        [`& input`]: {
            padding: '14px 10px',
            color: '#222',
            fontFamily: 'Helvetica Neue',
        },
        [`& textarea`]: {
            fontFamily: 'Helvetica Neue',
            // borderRadius: '25px'
        },
        '& label': {
            color: '#222',
        },
        '& label.Mui-focused': {
            color: '#222',
        },
        '& .MuiSelect-select': {
            fontFamily: 'Helvetica Neue',
        },
        '& .MuiOutlinedInput-root': {
            fontWeight: '200',
            '& fieldset': {
              borderColor: '#ccc',
            },
            '&:hover fieldset': {
              borderColor: '#ccc',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#ccc',
              borderWidth: '1px'
            },
        },
    },
    inputDarkWhiteBackground: {
        background: '#fff !important',
        color: '#222',
        marginBottom: '1rem',
        '& $notchedOutline': {
            borderColor: '#ccc !important',
        },
        [`& input`]: {
            background: '#fff !important',
            padding: '14px 10px',
            color: '#222',
            fontFamily: 'Helvetica Neue',
        },
        [`& textarea`]: {
            fontFamily: 'Helvetica Neue',
            // borderRadius: '25px'
        },
        '& label': {
            color: '#222',
        },
        '& .Mui-focused': {
            background: '#fff !important',
            color: '#222',
        },
        '& label.Mui-focused': {
            backgroundColor: '#fff !important',
            color: '#222',
        },
        '& .MuiSelect-select': {
            background: '#fff !important',
            fontFamily: 'Helvetica Neue',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#fff',
            fontWeight: '200',
            '& fieldset': {
              borderColor: '#ccc',
            },
            '&:hover fieldset': {
              borderColor: '#ccc',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#ccc',
              borderWidth: '1px'
            },
        },
    },
    underline: {
    "&:after": {
        borderBottom: "1px solid pink",
        borderTop: "1px solid pink"
    }
    },
    select: {
        color: '#222',
        '&:before': {
            borderColor: '#222',
        },
        '&:after': {
            borderColor: '#222',
        },
        '& $notchedOutline': {
            borderColor: '#ccc !important',
        },
        '& label': {
            color: '#222',
        }
    },
    icon: {
        fill: '#222',
    },
    input: {
        backgroundColor: 'transparent',
        color: '#fff',
        marginBottom: '1rem',
        '& $notchedOutline': {
            borderColor: '#ccc !important',
        },
        [`& input`]: {
            padding: '9px 10px',
            color: '#fff',
            fontFamily: 'Helvetica Neue',
        },
        [`& fieldset`]: {
            // borderRadius: '25px'
        },
        '& label': {
            color: '#fff',
        },
        '& label.Mui-focused': {
            color: '#fff',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#fff',
            },
            '&:hover fieldset': {
              borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#fff',
            },
        }
    },
    selectNoBackground: {
        [`&::placeholder`]: {
            color: 'red'
        },
        [`& .MuiSelect-select:focus`]: {
            backgroundColor: 'transparent'
        }
    },
    inputHighlighted: {
        backgroundColor: '#fff',
        borderRadius: '25px',
        '& $notchedOutline': {
            borderColor: '#f5b02e !important',
        },
        [`& input`]: {
            width: '7rem',
            padding: '9px 10px'
        },
        [`& fieldset`]: {
            borderRadius: '25px',
        },
    },
    success: {
        position: 'fixed',
        zIndex: 9999,
        top: '1rem',
        right: '2rem',
        marginRight: '2rem',
        backgroundColor: 'green',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            right: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            // minWidth: '25rem'
        },
    },
    error: {
        position: 'fixed',
        zIndex: 9999,
        top: '1rem',
        right: '2rem',
        marginRight: '2rem',
        backgroundColor: 'red',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            right: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            // minWidth: '25rem'
        },
    },
    info: {
        position: 'absolute',
        zIndex: 9999,
        top: '1rem',
        right: '2rem',
        marginRight: '2rem',
        backgroundColor: '#444',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            right: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            // minWidth: '25rem'
        },
    },
    authContainer: {
        height: '100vh',
        overflow: 'scroll',
        padding: '3rem',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }, 
        // backgroundColor: 'rgba(0,0,0)'
    },
    backgroundVideo: {
        [theme.breakpoints.up('xs')]: {
            objectFit: 'cover',
            height: '100%',
        }, 
        [theme.breakpoints.up('md')]: {
            objectFit: 'cover',
            width: '100%',
        },
    },
    mobile: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },  
    },
    desktop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    sidebarNav: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 8888
        },  
    },
    selectedConversation: {
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },  
    }
})

export default GlobalStyle;