import { useState } from 'react';
import FadeIn from 'react-fade-in';

import Index from './views/Authentication/Index';
import Dashboard from './views/Dashboard/Index';
import Legal from './views/Legal';

import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBN4wYoXlTODr44zAxm9GOmWGL9asGipt0",
  authDomain: "the-cut-life.firebaseapp.com",
  databaseURL: "https://the-cut-life.firebaseio.com",
  projectId: "the-cut-life",
  storageBucket: "the-cut-life.appspot.com",
  messagingSenderId: "431820819388",
  appId: "1:431820819388:web:8ceca555d6d8f5cdd279b9",
  measurementId: "G-BNEJDTS50G"
};

firebase.initializeApp(firebaseConfig);

function App() {
  const AUTH = firebase.auth();
  const DB = firebase.firestore();
  const CDN = firebase.storage();
  const [route, setRoute] = useState(window.location.pathname);
  const [stylist, setStylist] = useState(null);

  switch (route) {
    case '/':
      return <FadeIn><Index setRoute={setRoute} AUTH={AUTH} DB={DB} setStylist={setStylist}/></FadeIn>;
    case '/index':
      return <Dashboard stylist={stylist} setRoute={setRoute} firebase={firebase} AUTH={AUTH} DB={DB} CDN={CDN} initialDashboardScene={'overview'}/>;
    case '/legal':
      return <FadeIn><Legal setRoute={setRoute}/></FadeIn>;
    default:
      // ADD 404
      break;
  }

}

export default App;
