import { useState, useEffect } from 'react';
import GlobalStyle from '../../style/Global';
import FadeIn from 'react-fade-in';
import AddIcon from '@material-ui/icons/AddCircle';
import EmailIcon from '@material-ui/icons/Email';
import SendIcon from '@material-ui/icons/Send';
import ChatIcon from '@material-ui/icons/QuestionAnswer';
import CloseIcon from '@material-ui/icons/Close';
import {
    Grid,
    Typography,
    Button,
    IconButton,
    TextField,
    withStyles,
    InputAdornment,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    CircularProgress
} from '@material-ui/core';
import moment from 'moment';
import _ from 'underscore';


function Messages({
    firebase,
    DB,
    stylist,
    setMessage,
    classes
}) {
    const [loading, setLoading] = useState(true);
    const [newConversationModalVisible, setNewConversationModalVisible] = useState(false);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [clients, setClients] = useState(null);
    const [client, setClient] = useState({});
    const [conversations, setConversations] = useState([]);
    const [newConversationLoading, setNewConversationLoading] = useState(false);
    const [newMessageLoading, setNewMessageLoading] = useState(false);
    const [showDeleteConversationDialog, setShowDeleteConversationDialog] = useState(false);
    const [conversationToDelete, setConversationToDelete] = useState(null);
    const [deletingConversation, setDeletingConversation] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const [newConversationMessage, setNewConversationMessage] = useState('');

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }

    const sendNewMessage = () => {
        if (!newMessage) {
            showFeedback("You can't send an empty message...", classes.error);
        } else {
            setNewMessageLoading(true);

            DB.collection('conversations').doc(selectedConversation.id).update({
                messages: firebase.firestore.FieldValue.arrayUnion({
                    author: stylist,
                    content: newMessage,
                    createdAt: firebase.firestore.Timestamp.now()
                }),
                updatedAt: firebase.firestore.Timestamp.now(),
                hasReadNewMessages: firebase.firestore.FieldValue.arrayRemove(selectedConversation.client.memberId)
            })
            .then(() => {
                getConversations(selectedConversation);
                setNewMessage('');
                setNewMessageLoading(false);
                showFeedback('message sent successfully!', classes.success);
            })
            .catch((error) => {
                setNewMessageLoading(false);
                showFeedback(error.message, classes.error);
            })
        }
    };

    const deleteConversation = () => {
        setDeletingConversation(true);
        
        DB.collection('conversations').doc(conversationToDelete).update({
            isDeleted: true
        })
        .then(() => {
            setConversationToDelete(null);
            setShowDeleteConversationDialog(false);
            setDeletingConversation(false);
            getConversations();
            showFeedback('conversation deleted', classes.success);
        })
        .catch((error) => {
            setNewMessageLoading(false);
            showFeedback(error.message, classes.error);
        })
    }

    const createConversation = () => {
        if (!newConversationMessage) {
            showFeedback("You can't send an empty message...", classes.error);
        } else {
            setNewConversationLoading(true);

            DB.collection('conversations').add({
                messages: [{
                    author: stylist,
                    content: newConversationMessage,
                    createdAt: firebase.firestore.Timestamp.now()
                }],
                client: client,
                stylist: stylist,
                participants: [stylist.id, client.memberId],
                hasReadNewMessages: [stylist.id],
                updatedAt: firebase.firestore.Timestamp.now(),
                isDeleted: false
            })
            .then(() => {
                setClient({});
                setNewConversationMessage('');
                setNewConversationLoading(false);
                setNewConversationModalVisible(false);
                getConversations(null, true);
                showFeedback("Message sent successfully", classes.success);
            })
            .catch((error) => {
                setNewConversationLoading(false);
                showFeedback(error.message, classes.error);
            })
        }
    }

    const getClients = () => {
        DB.collection('clients').where('stylistId', '==', stylist.id).get()
        .then((clientsSnapshot) => {
            const clientsContainer = [];

            clientsSnapshot.forEach((clientDocument) => {
                const client = clientDocument.data();

                clientsContainer.push(client);
            })

            setClients(clientsContainer);
        })
        .catch((error) => {
            showFeedback(error.message, classes.error);
        })
    }

    const getConversations = (selectedConversation = null, newConversation = false) => {
        DB.collection('conversations').where('stylist.id', '==', stylist.id).where('isDeleted', '==', false).get()
        .then((conversationsSnapshot) => {
            let conversationsContainer = [];

            conversationsSnapshot.forEach((conversationDocument) => {
                const conversation = conversationDocument.data();
                conversation.id = conversationDocument.id;

                conversationsContainer.push(conversation);
            });

            if (selectedConversation && !newConversation) {
                setSelectedConversation(_.find(conversationsContainer, (conversation) => conversation.id === selectedConversation.id));

                DB.collection('conversations').doc(selectedConversation.id).update({
                    hasReadNewMessages: firebase.firestore.FieldValue.arrayUnion(stylist.id)
                });
            } else if (newConversation) {
                setSelectedConversation(conversationsContainer[conversationsContainer.length - 1]);
            }

            if (conversationsContainer.length === 0) {
                setSelectedConversation(null);
            }

            conversationsContainer = _.sortBy(conversationsContainer, 'updatedAt').reverse();
            setConversations(conversationsContainer);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error.message, classes.error);
        })
    }

    useEffect(() => {
        getClients();
        getConversations();
    }, [])

    useEffect(() => {
        document.querySelector("#selectedConversation").scrollTop = document.querySelector(
            "#selectedConversation"
          ).scrollHeight;
    }, [selectedConversation])

    return (
        <div>
            <FadeIn>
                <Grid container item xs={12} style={{padding: '1rem'}}>
                    <Grid item xs={12} style={{position: 'relative', color: '#222', padding: '1rem'}}>
                        <Typography style={{
                            fontFamily: 'helvetica neue',
                            fontSize: '1.5rem',
                        }}>
                            Messages
                        </Typography>
                        <div style={{flexGrow: 1}}/>
                        <IconButton onClick={() => clients.length > 0 ? setNewConversationModalVisible(true) : showFeedback("You don't have any clients to message, yet.", classes.info)} aria-label="add" style={{position: 'absolute', top: '20%', right: 0}}>
                            <AddIcon style={{color: '#222'}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} md={4} style={{backgroundColor: '#eee', height: '80vh', padding: '1rem', border: '1px solid #ccc'}}>
                        {loading && <div style={{textAlign: 'center', paddingTop: '3rem'}}><CircularProgress size={50} style={{color: '#444'}}/></div>}
                        {!loading && conversations.length === 0 && <div style={{textAlign: 'center', marginTop: '2rem'}}>
                            <Typography>Stay Connected</Typography>
                            <Typography style={{fontFamily: 'helvetica neue', fontWeight: '200'}}>
                                Converse with your clients directly.
                            </Typography>
                            <Button onClick={() => clients.length > 0 ? setNewConversationModalVisible(true) : showFeedback("You don't have any clients to message, yet.", classes.info)} variant="contained" size="small" style={{backgroundColor: '#444', color: '#f5f5f5', marginTop: '0.5rem'}}>Send a Message</Button>
                        </div>}
                        {!loading && conversations.length > 0 && conversations.map((conversation, currentIndex) => {
                            return (
                                <Grid key={conversation.id} onClick={() => setSelectedConversation(conversation)} item container md={12} style={conversation.hasReadNewMessages.indexOf(stylist.id) === -1 ? {position: 'relative', cursor: 'pointer', backgroundColor: '#444', color: '#f5f5f5', border: '1px solid #ccc', borderRadius: '5px', marginBottom: '1rem', padding: '0.5rem'} : {position: 'relative', cursor: 'pointer', backgroundColor: '#f5f5f5', border: '1px solid #ccc', borderRadius: '5px', marginBottom: '1rem', padding: '0.5rem'}}>
                                    <Grid item xs={2} style={{textAlign: 'center'}}>
                                        <EmailIcon style={{fontSize: '2rem', paddingTop: '0.5rem'}}/>
                                    </Grid>
                                    <Grid item xs={10}  >
                                        <Typography>{conversation.client.firstName} {conversation.client.lastName}</Typography>
                                        <Typography style={{fontFamily: 'helvetica neue', fontWeight: '400', lineHeight: 1, height: '1.1rem', textOverflow: 'ellipsis', overflow: 'hidden'}}>{conversation.messages[conversation.messages.length - 1].content}</Typography>
                                        <Typography style={{fontFamily: 'helvetica neue', fontWeight: '400', fontStyle: 'italic', fontSize: '0.8rem'}}>{moment(conversation.updatedAt.toDate()).fromNow()}</Typography>
                                    </Grid>
                                    <CloseIcon onClick={() => {setConversationToDelete(conversation.id); setShowDeleteConversationDialog(true)}} style={{position: 'absolute', top: '3px', right: '3px', zIndex: 2, fontSize: '1rem'}}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid item id="selectedConversation" xs={12} md={8} className={classes.selectedConversation} style={{display: `flex ${selectedConversation ? '!important' : ''}`, flexDirection: 'column', border: '1px solid #ccc', height: '80vh', overflowY: 'scroll'}}>
                        {!loading && !selectedConversation && <ChatIcon style={{color: '#ddd', fontSize: '25rem', alignSelf: 'center', marginTop: '10rem', maxWidth: '90%'}}/>}
                        {loading && <div style={{textAlign: 'center', paddingTop: '3rem'}}><CircularProgress size={200} style={{color: '#444'}}/></div>}
                        {!loading && selectedConversation && selectedConversation.messages.map((message, messageIndex) => { return (
                                <div key={messageIndex} style={{clear: 'both', padding: '1rem'}}>
                                    <div style={message.author !== stylist.id ? {backgroundColor: '#ddd', borderRadius: '5px', padding: '1rem', maxWidth: '35%', float: 'right'} : {color: '#f5f5f5', backgroundColor: '#444', borderRadius: '5px', padding: '1rem', maxWidth: '35%', float: 'left'}}>
                                        <Typography style={{fontFamily: 'helvetica neue', overflowWrap: 'break-word', marginBottom: '1rem'}}>{message.content}</Typography>
                                        <Typography>-{message.author.id === stylist.id ? 'me' : message.author.firstName}</Typography>
                                        <Typography style={{fontSize: '0.8rem', fontStyle: 'italic'}}>{moment(message.createdAt.toDate()).fromNow()}</Typography>
                                    </div>
                                </div>
                        )})}
                        {selectedConversation && <div style={{flexGrow: 1}}></div>}
                        {selectedConversation && <div style={{position: 'sticky', bottom: 0, width: '100%'}}>
                            <TextField
                                disabled={newMessageLoading}
                                placeholder="enter message..."
                                variant="filled"
                                style={{width: '100%', backgroundColor: '#ddd'}}
                                value={newMessageLoading ? 'sending...' : newMessage}
                                onChange={(event) => setNewMessage(event.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><IconButton onClick={sendNewMessage} disabled={newMessageLoading} aria-label="send"><SendIcon/></IconButton></InputAdornment>
                                }}
                            />
                        </div>}
                    </Grid>
                </Grid>
            </FadeIn>
            <Dialog open={newConversationModalVisible} onClose={() => { setNewConversationModalVisible(false); setNewConversationMessage(''); setClient({})}} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{newConversationLoading ? <CircularProgress size={25} style={{color: '#444'}}/> : 'Message Client'}</DialogTitle>
                {/* <DialogContentText style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
                    .......
                </DialogContentText> */}
                <DialogContent>
                    <TextField
                        fullWidth
                        select
                        label="Client"
                        variant="outlined"
                        value={client && client}
                        onChange={(event) => setClient(event.target.value)}
                        className={`${classes.inputDark} ${classes.blockInput}`}
                    >
                        {clients && clients.map((client) => { return (<MenuItem key={client.memberId} value={client}>{`${client.firstName} ${client.lastName}`}</MenuItem>)})}
                    </TextField>
                    <TextField
                        fullWidth
                        multiline
                        rows={7}
                        disabled={loading}
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        label="Message"
                        value={newConversationMessage}
                        onChange={(event) => setNewConversationMessage(event.target.value)}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => {setNewConversationModalVisible(false); setNewConversationMessage(null); setClient({}); setNewConversationMessage('')}} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem'}}>
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={() => createConversation()} variant="contained" style={{backgroundColor: '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showDeleteConversationDialog} onClose={() => setShowDeleteConversationDialog(false) } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{deletingConversation ? <CircularProgress size={25} style={{color: '#444'}}/> : 'Are You Sure?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are about to delete this conversation.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={deletingConversation} onClick={() => setShowDeleteConversationDialog(false)} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem'}}>
                        Cancel
                    </Button>
                    <Button disabled={deletingConversation} onClick={deleteConversation} variant="contained" style={{backgroundColor: '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                        Yes, delete this conversation.
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default withStyles(GlobalStyle)(Messages);