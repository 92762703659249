import { useState } from 'react';
import GlobalStyle from '../../style/Global';
import FadeIn from 'react-fade-in';
import AddIcon from '@material-ui/icons/AddCircle';
import {
    Grid,
    Typography,
    IconButton,
    withStyles
} from '@material-ui/core';


function Orders({}) {

    // const [conversations, setConversations] = useState([
    //     {demo: 1},
    //     {demo: 2},
    //     {demo: 3}
    // ]);

    return (
        <div>
            <FadeIn>
                <Grid container item xs={12} style={{padding: '1rem'}}>
                    <Grid item xs={12} style={{position: 'relative', color: '#222', padding: '1rem'}}>
                        <Typography style={{
                            fontFamily: 'helvetica neue',
                            fontSize: '1.5rem',
                        }}>
                            Orders
                        </Typography>
                        <div style={{flexGrow: 1}}/>
                        <IconButton aria-label="add" style={{position: 'absolute', top: '30%', right: '1rem'}} size="large">
                            <AddIcon style={{color: '#222'}}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </FadeIn>
        </div>
    )
}

export default withStyles(GlobalStyle)(Orders);