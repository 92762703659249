import { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Paper,
    Button,
    IconButton,
    CircularProgress,
    withStyles
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import {
    DataGrid
} from '@material-ui/data-grid';

import GlobalStyle from '../../style/Global';
import FadeIn from 'react-fade-in';
import { CSVDownload } from 'react-csv';
import moment from 'moment';


function Clients ({
    DB,
    stylist,
    setMessage,
    classes
}) {

    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState(null);
    const [clientData, setClientData] = useState([]);
    const [clientDataDownloadable, setClientDataDownloadable] = useState(false);

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }

    const getClients = () => {
        DB.collection('clients').where('stylistId', '==', stylist.id).get()
        .then((clientsSnapshot) => {
            const clientsContainer = [];

            clientsSnapshot.forEach((clientDocument) => {
                const client = clientDocument.data();
                client.id = clientDocument.id;

                clientsContainer.push(client);
            })

            setClients(clientsContainer);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error.message, classes.error);
        })
    }

    const columns = [
        {field: 'name', headerName: 'Name', valueGetter: (params) => 
            `${params.getValue('firstName')} ${params.getValue('lastName')}`,
            width: 200
        },
        {field: 'emailAddress', headerName: 'Email', width: 225},
        {field: 'phoneNumber', headerName: 'Phone', width: 150},
        {field: 'appointments', headerName: 'Appointments', valueGetter: (params) => 
            `${params.getValue('metrics').appointments}`,
            width: 150
        },
        {field: 'revenue', headerName: 'Revenue', valueGetter: (params) => 
            `$${(params.getValue('metrics').revenue/100).toFixed(2)}`,
            width: 100
        },
        {field: 'lastAppointment', headerName: 'Last Appointment', valueGetter: (params) => 
            `${moment(params.getValue('metrics').lastAppointment.toDate()).fromNow()}`,
            width: 200
        },
    ]

    const createClientCSV = () => {
        const formattedCSV = [["First Name", "Last Name", "Email Address", "Phone Number", "Appointments", "Revenue", "Last Appointment"]];

        clients.forEach((client) => {
            formattedCSV.push([client.firstName, client.lastName, client.emailAddress, client.phoneNumber, client.metrics.appointments, `$${(client.metrics.revenue/100).toFixed(2)}`, moment(client.metrics.lastAppointment.toDate()).format('MMM Do, YYYY')]);
        });

        setClientData(formattedCSV);
        setClientDataDownloadable(true);
    }

    useEffect(() => {
        getClients();
    }, [])


    return (
        <div style={{width: '100%'}}>
            <FadeIn>
                <Grid container item xs={12} style={{padding: '1rem'}}>
                    <Grid item md={12} style={{position: 'relative', color: '#222', padding: '1rem'}}>
                        <Typography style={{
                            fontFamily: 'helvetica neue',
                            fontSize: '1.5rem',
                        }}>
                            Clients
                        </Typography>
                        {clients && clients.length > 0 && <IconButton onClick={createClientCSV} variant="contained" style={{position: 'absolute', top: '30%', right: '1rem', textTransform: 'none'}}>
                            <DownloadIcon/>
                        </IconButton>}
                    </Grid>
                    <Grid item xs={12} style={{height: '80vh', padding: '1rem'}}>
                        {loading && <div style={{textAlign: 'center'}}><CircularProgress size={25} style={{marginTop: '5rem', alignSelf: 'center', color: '#444'}}/></div>}
                        {!loading && (!clients || clients.length === 0) && <Typography style={{marginTop: '1rem', fontFamily: 'helvetica neue', fontSize: '1rem', fontWeight: '400'}}>You don't have any clients, yet.</Typography>}
                        {!loading && clients && clients.length > 0 && <Paper style={{height: '100%'}} className={classes.tcl}>
                            {!loading && clients && clients.length > 0 && <DataGrid rows={clients} columns={columns} pageSize={25}/>}
                        </Paper>}
                    </Grid>
                </Grid>
                {clientDataDownloadable && <CSVDownload data={clientData} filename={`client_data_${moment().format('MMM')}_${moment().format('YYYY')}`} target="_blank" />}
            </FadeIn>
        </div>
    )
}

export default withStyles(GlobalStyle)(Clients);