import {
    Grid,
    Button,
    withStyles
} from "@material-ui/core";

import GlobalStyle from '../style/Global';
import logo from '../assets/images/logo_light.png';

function Nav({
    currentScene,
    setDashboardScene,
    links,
    bgColor = 'transparent'
}) {

    return (
        <Grid container item xs={12} style={{height: '100vh', overflowY: 'scroll'}}>
            <Grid item xs={12} style={{backgroundColor: bgColor, borderRightColor: '#222', borderRightStyle: 'solid', borderRightWidth: '3px', paddingTop: '1rem', textAlign: 'center'}}>
                <img alt="logo" src={logo} style={{height: '5rem', margin: '1rem'}}/>
                {links.map((link) => { return (
                    <Button
                        key={link.title}
                        size="large"
                        startIcon={link.icon}
                        onClick={() => link.title === 'log out' ? window.location = 'https://dashboard.thecutlife.com' : setDashboardScene(link.title === 'dashboard' ? 'index' : link.title)}
                        style={{
                            justifyContent: 'flex-start',
                            marginBottom: 0,
                            paddingLeft: '2rem',
                            textTransform: 'capitalize',
                            fontFamily: 'helvetica',
                            fontWeight: '200',
                            color: currentScene === link.title ? 'goldenrod' : '#f5f5f5'
                        }}
                        fullWidth
                    >
                        {link.title}
                    </Button>
                )})}
            </Grid>
        </Grid>
    )
}

export default withStyles(GlobalStyle)(Nav);