import { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Paper,
    withStyles
} from '@material-ui/core';

import EventIcon from '@material-ui/icons/Event';
import CartIcon from '@material-ui/icons/ShoppingCart';

import AppointmentList from '../../components/AppointmentList';
import GlobalStyle from '../../style/Global';
import NumberFormat from 'react-number-format';
import FadeIn from 'react-fade-in';
import moment from 'moment';


function Overview ({
    DB,
    setMessage,
    stylist,
    classes
}) {
    const [loading, setLoading] = useState(true);
    const [appointmentsLoading, setAppointmentsLoading] = useState(true);
    const [appointments, setAppointments] = useState([]);


    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }

    const getAppointments = () => {
        DB.collection('appointments').where('stylist.id', '==', stylist.id).orderBy('startTimeDate.timestamp', 'desc').limit(20).get()
        .then((appointmentsSnapshot) => {
            const appointmentsContainer = [];
            appointmentsSnapshot.forEach((appointmentDoc) => {
                const appointment = appointmentDoc.data();
                appointment.id = appointmentDoc.id;

                appointmentsContainer.push(appointment);
            })

            setAppointments(appointmentsContainer);
            setAppointmentsLoading(false);
        })
        .catch((error) => {
            showFeedback(error.message, classes.error);
            setLoading(false);
        })
    }

    useEffect(() => {
        getAppointments();
    }, [])

    return (
        <div style={{minHeight: '100vh'}}>
            <FadeIn>
                <Grid container item xs={12} style={{padding: '1rem'}}>
                    <Grid item xs={12} md={12} style={{color: '#222', padding: '1rem'}}>
                        <Typography style={{fontFamily: 'helvetica neue', fontSize: '1.5rem'}}>Hi, {stylist && stylist.firstName}!</Typography>
                    </Grid>
                    <Grid item xs={6} md={3} style={{color: '#222', padding: '1rem'}}>
                        <Typography style={{fontSize: '1.75rem', fontFamily: 'helvetica', fontWeight: '700', lineHeight: 1, color: '#222'}}>
                            <NumberFormat displayType="text" value={stylist.private.earnings/100} thousandSeparator={true} decimalScale="2" fixedDecimalScale prefix={'$'} />
                        </Typography>
                        <Typography style={{fontSize: '1.25rem', fontFamily: 'helvetica neue', fontWeight: '400'}}>earnings</Typography>
                    </Grid>
                    <Grid item xs={6} md={3} style={{color: '#222', padding: '1rem'}}>
                        <Typography style={{fontSize: '1.75rem', fontFamily: 'helvetica', fontWeight: '700', lineHeight: 1, color: '#222'}}>
                            <NumberFormat displayType="text" value={stylist.private.appointments} />
                        </Typography>
                        <Typography style={{fontSize: '1.25rem', fontFamily: 'helvetica neue', fontWeight: '400'}}>appointments</Typography>
                    </Grid>
                    <Grid item xs={6} md={3} style={{color: '#222', padding: '1rem'}}>
                        <Typography style={{fontSize: '1.75rem', fontFamily: 'helvetica', fontWeight: '700', lineHeight: 1, color: '#222'}}>{stylist.private.clients}</Typography>
                        <Typography style={{fontSize: '1.25rem', fontFamily: 'helvetica neue', fontWeight: '400'}}>clients</Typography>
                    </Grid>
                    <Grid item xs={6} md={3} style={{color: '#222', padding: '1rem'}}>
                        <Typography style={{fontSize: '1.75rem', fontFamily: 'helvetica', fontWeight: '700', lineHeight: 1, color: '#222'}}>
                            <NumberFormat displayType="text" value={stylist.private.averageRating} decimalScale="2" fixedDecimalScale />
                        </Typography>
                        <Typography style={{fontSize: '1.25rem', fontFamily: 'helvetica neue', fontWeight: '400'}}>avg rating</Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{minHeight: '25rem'}}>
                    <Grid item xs={12} md={12} style={{padding: '1rem'}}>
                        <Typography style={{marginBottom: '0.5rem'}}><EventIcon style={{verticalAlign: 'bottom', color: 'goldenrod'}}/> Upcoming Appointments</Typography>
                        {appointmentsLoading ? <Typography>loading...</Typography> : (appointments.length < 1 ? <Typography style={{fontWeight: '200', fontFamily: 'helvetica neue', marginLeft: '3px'}}>No upcoming appointments</Typography> : <Paper style={{height: '100%'}} className={classes.tcl}>   
                            <AppointmentList appointments={appointments}/>
                        </Paper>)}
                    </Grid>
                </Grid>
            </FadeIn>
        </div>
    )
}

export default withStyles(GlobalStyle)(Overview);