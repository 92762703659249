import { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Button,
    IconButton,
    InputAdornment,
    CircularProgress,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    withStyles
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddCircle';
import ActiveIcon from '@material-ui/icons/CheckCircle';
import InactiveIcon from '@material-ui/icons/Cancel';

import GlobalStyle from '../../style/Global';
import FadeIn from 'react-fade-in';


function Services ({
    classes,
    stylist,
    setMessage,
    DB,
    CDN,
}) {

    const initialServiceData = {
        name: null,
        description: null,
        duration: null,
        image: null,
        stylistId: stylist.id,
        price: 0,
        placeholderImage: 'https://firebasestorage.googleapis.com/v0/b/the-cut-life.appspot.com/o/placeholder.jpg?alt=media&token=b40c0e15-4984-4038-a7c4-578602bf7d7e',
        active: true
    };

    const [loading, setLoading] = useState(false);
    const [service, setService] = useState(initialServiceData);
    const [services, setServices] = useState(null);
    const [editing, setEditing] = useState(false);
    const [serviceFormVisible, setServiceFormVisible] = useState(false);
    const [image, setImage] = useState(null);

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }
    
    const getServices = () => {
        DB.collection('services').where('stylistId', '==', stylist.id).get()
        .then((servicesSnapshot) => {
            const servicesContainer = [];

            servicesSnapshot.forEach((serviceDocument) => {
                const service = serviceDocument.data();
                service.id = serviceDocument.id;

                servicesContainer.push(service);
            });

            setServices(servicesContainer);
            setLoading(false);
        })
    }

    const addService = () => {
        if (!service.name || !service.description || !service.duration || !service.price) {
            return showFeedback('Missing service details...', classes.error);
        }

        setLoading(true);

        DB.collection('services').add({...service, price: service.price})
        .then((serviceRef) => {
            if (!image) {
                showFeedback('Service added successfully!', classes.success);
                getServices();
                setServiceFormVisible(false);
                setService(initialServiceData);
                setImage(null);
                return;
            }

            const imageRef = CDN.ref().child(`services/images/${serviceRef.id}`);

            imageRef.put(image)
            .then(() => {
                imageRef.getDownloadURL()
                .then((url) => {
                    serviceRef.update({
                        image: url
                    })
                    .then(() => {
                        showFeedback('Service added successfully!', classes.success);
                        getServices();
                        setServiceFormVisible(false);
                        setService(initialServiceData);
                        setImage(null);
                    })
                    .catch((error) => {
                        setLoading(false);
                        showFeedback(error, classes.error);
                    })
                })
                .catch((error) => {
                    setLoading(false);
                    showFeedback(error, classes.error);
                })
            })
            .catch((error) => {
                setLoading(false);
                showFeedback(error, classes.error);
            })
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error, classes.error);
        })
    };

    const updateService = (toggleServiceAvailability = false) => {
        if (!service.name || !service.description || !service.duration || !service.price) {
            return showFeedback('Missing service details...', classes.error);
        }

        setLoading(true);

        if (toggleServiceAvailability) {
            return DB.collection('services').doc(service.id).update({
                active: !service.active
            })
            .then(() => {
                getServices();
                showFeedback(`Service ${service.active ? 'deactivated' : 'activated'} successfully!`, classes.success);
                setServiceFormVisible(false);
                setEditing(false);
                setService(initialServiceData);
                setImage(null);
            })
        }

        const serviceRef = DB.collection('services').doc(service.id);
        
        serviceRef.update({...service})
        .then(() => {
            if (!image) {
                showFeedback('Service updated successfully!', classes.success);
                getServices();
                setServiceFormVisible(false);
                setService(initialServiceData);
                setEditing(false);
                setImage(null);
                return;
            }

            const imageRef = CDN.ref().child(`services/images/${service.id}`);

            imageRef.put(image)
            .then(() => {
                imageRef.getDownloadURL()
                .then((url) => {
                    serviceRef.update({
                        image: url
                    })
                    .then(() => {
                        showFeedback('Service updated successfully!', classes.success);
                        getServices();
                        setServiceFormVisible(false);
                        setService(initialServiceData);
                        setImage(null);
                    })
                    .catch((error) => {
                        setLoading(false);
                        showFeedback(error, classes.error);
                    })
                })
                .catch((error) => {
                    setLoading(false);
                    showFeedback(error, classes.error);
                })
            })
            .catch((error) => {
                setLoading(false);
                showFeedback(error, classes.error);
            })
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error, classes.error);
        })
    };

    const clearService = () => {
        setServiceFormVisible(false);
        setEditing(false);
        setService(initialServiceData);
        setImage(null);
    }

    const durationStrings = [
        null,
        '15 Minutes',
        '30 Minutes',
        '45 Minutes',
        '1 Hour',
        '1 Hour 15 Minutes',
        '1 Hour 30 Minutes',
        '1 Hour 45 Minutes',
        '2 Hours',
        '2 Hours 15 Minutes',
        '2 Hours 30 Minutes',
        '2 Hours 45 Minutes',
        '3 Hours',
        '3 Hours 15 Minutes',
        '3 Hours 30 Minutes',
        '3 Hours 45 Minutes',
        '4 Hours+',
    ];

    useEffect(() => {
        getServices();
    }, [])

    return (
        <div>
            <FadeIn>
                <Grid container item xs={12} style={{padding: '1rem'}}>
                    <Grid item xs={12} style={{position: 'relative', color: '#222', padding: '1rem'}}>
                        <Typography style={{
                            fontFamily: 'helvetica neue',
                            fontSize: '1.5rem',
                        }}>
                            Services
                        </Typography>
                        <div style={{flexGrow: 1}}/>
                        <IconButton aria-label="add" onClick={() => setServiceFormVisible(true)} style={{position: 'absolute', top: '30%', right: '1rem'}}>
                            <AddIcon style={{color: '#222'}}/>
                        </IconButton>
                    </Grid>
                    {services && services.length < 1 && <Typography style={{marginTop: '1rem', padding: '1rem', fontFamily: 'helvetica neue', fontSize: '1rem', fontWeight: '400'}}>You haven't added any services, yet.</Typography>}
                    {services && services.length > 0 && services.map((service) => { return (<Grid item key={service.id} xs={12} md={4} style={{padding: '1rem'}}>
                        <Card onClick={() => {setService(service); setEditing(true); setServiceFormVisible(true)}}>
                            <CardActionArea>
                                <CardMedia
                                    style={{height: '250px', position: 'top'}}
                                    image={service.image ? service.image : service.placeholderImage}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2" style={{color: 'goldenrod'}}>
                                        {service.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        <span style={{display: 'block'}}>
                                            Price: <span style={{
                                                fontFamily: 'helvetica neue',
                                                fontWeight: '400',
                                            }}>${parseFloat(service.price/100)}</span>
                                        </span>
                                        <span style={{display: 'block'}}>
                                            Duration: <span style={{
                                                fontFamily: 'helvetica neue',
                                                fontWeight: '400',
                                            }}>{durationStrings[service.duration]}</span>
                                        </span>
                                        <span style={{display: 'block', marginBottom: '1rem'}}>
                                            Active: <span style={{
                                                fontFamily: 'helvetica neue',
                                                fontWeight: '400',
                                            }}>{service.active ? <ActiveIcon style={{verticalAlign: 'middle', fontSize: '1rem', color: 'forestgreen'}}/> : <InactiveIcon style={{verticalAlign: 'middle', fontSize: '1rem', color: 'red'}}/> }</span>
                                        </span>
                                        <span style={{
                                            display: 'block',
                                            fontFamily: 'helvetica neue',
                                            fontWeight: '400',
                                            overflow: 'hidden',
                                            width: '100%',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {service.description}
                                        </span>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            {/* <CardActions>
                                <div style={{flexGrow: 1}}/>
                                <Button size="small">
                                    Edit
                                </Button>
                            </CardActions> */}
                        </Card>
                    </Grid>)})}
                </Grid>
            </FadeIn>

            <Dialog open={serviceFormVisible} onClose={clearService} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: 'goldenrod'}}/> : editing ? 'Edit Service' : 'Add Service'}</DialogTitle>
                {editing && <DialogContentText style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
                    Active: {service && service.active ? <ActiveIcon style={{verticalAlign: 'middle', fontSize: '1rem', color: 'forestgreen'}}/> : <InactiveIcon style={{verticalAlign: 'middle', fontSize: '1rem', color: 'red'}}/>}
                </DialogContentText>}
                <DialogContent>
                    <TextField
                        fullWidth
                        disabled={loading}
                        label="Name"
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        value={service && service.name}
                        onChange={(event) => setService({...service, name: event.target.value})}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        multiline
                        rows={7}
                        disabled={loading}
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        label="Description"
                        value={service && service.description}
                        onChange={(event) => setService({...service, description: event.target.value})}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        select
                        label="Duration"
                        variant="outlined"
                        value={service && service.duration}
                        onChange={(event) => setService({...service, duration: event.target.value})}
                        className={`${classes.inputDark} ${classes.blockInput}`}
                    >
                        {durationStrings.map((duration, durationIndex) => { 
                            if (durationIndex === 0) {
                                return;
                            } 
                            return (<MenuItem key={duration} value={durationIndex}>{duration}</MenuItem>)
                        })}
                    </TextField>
                    <TextField
                        disabled={loading}
                        type="number"
                        label="Price"
                        style={{marginRight: '1rem'}}
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        value={service && (service.price/100).toString()}
                        onChange={(event) => setService({...service, price: parseInt(event.target.value*100, 10)})}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <TextField
                        disabled={loading}
                        type="file"
                        className={`${classes.inputDark} ${classes.blockInput}`}
                        variant="outlined"
                        helperText="Service Image (min. 500x500)"
                        onChange={(event) => setImage(event.target.files[0])}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />

                </DialogContent>
                <DialogActions style={{display: 'block', padding: '1rem 1.5rem 0'}}>
                    <Button fullWidth disabled={loading} onClick={() => editing ? updateService() : addService()} variant="contained" style={{backgroundColor: '#222', color: '#fff', textTransform: 'none', marginBottom: '1rem'}}>
                        {editing ? 'Update' : 'Add'} Service
                    </Button>
                    <Button fullWidth disabled={loading} onClick={clearService} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginLeft: 0, marginBottom: '1rem'}}>
                        Cancel
                    </Button>
                    {editing && <Button fullWidth disabled={loading} onClick={() => updateService(true)} variant="contained" style={{backgroundColor: service.active ? 'red' : 'forestgreen', color: '#f5f5f5', textTransform: 'none', marginLeft: 0, marginBottom: '1rem'}}>
                        {service && service.active ? 'Deactivate' : 'Activate'}
                    </Button>}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default withStyles(GlobalStyle)(Services);