import { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Button,
    IconButton,
    InputAdornment,
    CircularProgress,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    withStyles
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import ActiveIcon from '@material-ui/icons/CheckCircle';
import InactiveIcon from '@material-ui/icons/Cancel';

import GlobalStyle from '../../style/Global';
import FadeIn from 'react-fade-in';


function Courses ({
    classes,
    stylist,
    setMessage,
    DB,
    CDN,
}) {

    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState(null);

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }
    
    const getCourses = () => {
        DB.collection('courses').where('stylistId', '==', stylist.id).get()
        .then((coursesSnapshot) => {
            const coursesContainer = [];

            coursesSnapshot.forEach((courseDocument) => {
                const course = courseDocument.data();
                course.id = courseDocument.id;

                coursesContainer.push(course);
            });

            setCourses(coursesContainer);
            setLoading(false);
        })
    }


    useEffect(() => {
        getCourses();
    }, [])

    return (
        <div>
            <FadeIn>
                <Grid container item xs={12} style={{padding: '1rem'}}>
                    <Grid item xs={12} style={{position: 'relative', color: '#222', padding: '1rem'}}>
                        <Typography style={{
                            fontFamily: 'helvetica neue',
                            fontSize: '1.5rem',
                        }}>
                            Classes
                        </Typography>
                        <div style={{flexGrow: 1}}/>
                        <IconButton aria-label="search" style={{position: 'absolute', top: '30%', right: '1rem'}}>
                            <SearchIcon style={{color: '#222'}}/>
                        </IconButton>
                    </Grid>
                    {courses && courses.length < 1 && <Typography style={{marginTop: '1rem', padding: '1rem', fontFamily: 'helvetica neue', fontSize: '1rem', fontWeight: '400'}}>You haven't saved any classes, yet.</Typography>}
                </Grid>
            </FadeIn>
        </div>
    )
}

export default withStyles(GlobalStyle)(Courses);